import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { RegExPatterns } from '@app/_constants/regexPatterns';
import { AdminService } from '@app/_services/admin.service';
import { AlertsService } from '@app/_services/alerts.service';
import { CommonService } from '@app/_services/common.service';
import { FundHouseDto } from '@app/_services/dto/fund-house.dto';
import { LocalStorageService } from '@app/_services/local-storage.service';
import { SessionStorageService } from '@app/_services/session-storage.service';
import { AppUserBaseComponent } from '@app/app-user-base.component';
import { first } from 'rxjs';

@Component({
  selector: 'app-add-update-fund-house',
  templateUrl: 'add-update-fund-house.component.html',
  styleUrls: ['add-update-fund-house.component.scss']
})
export class AddUpdateFundHouseComponent extends AppUserBaseComponent implements OnInit {

  addFundHouseForm!: FormGroup;
  fundHouseDto: FundHouseDto = new FundHouseDto();
  submitted = false;
  loading = false;
  error = '';

  constructor(
    public dialogRef: MatDialogRef<AddUpdateFundHouseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FundHouseDto,
    private fb: FormBuilder,
    private alertsService: AlertsService,
    private adminService: AdminService,
    localStorageService: LocalStorageService,
    activatedRoute: ActivatedRoute,
    commonService: CommonService,
    sessionStorageService: SessionStorageService,
  ) {

    super(activatedRoute, localStorageService, sessionStorageService, commonService);

    dialogRef.disableClose = false;
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
  }

  public get isUpdate(): boolean {
    return (this.data && this.data.id != null);
  }

  ngOnInit() {
    this.addFundHouseForm = this.fb.group({
      id: [''],
      shortName: ['', [Validators.required, Validators.pattern(RegExPatterns.validShortName)]],
      fullName: ['', Validators.required],
      logoImageFileName: ['', this.isUpdate ? [] : Validators.required]
    }
    );

    if (this.isUpdate) {
      this.addFundHouseForm.controls.id.setValue(this.data.id);
      this.addFundHouseForm.controls.shortName.setValue(this.data.shortName);
      this.addFundHouseForm.controls.fullName.setValue(this.data.fullName);
    }
  }

  get addFundHouseFormControl() {
    return this.addFundHouseForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.addFundHouseForm.valid) {
      let formData = new FormData();
      formData.append('id', this.addFundHouseFormControl.id.value);
      formData.append('shortName', this.addFundHouseFormControl.shortName.value);
      formData.append('fullName', this.addFundHouseFormControl.fullName.value);
      formData.append('logoImage', this.selectedLogoImage);

      this.loading = true;
      this.adminService.addUpdateFundHouse(formData)
        .pipe(first())
        .subscribe({
          next: (response) => {
            this.submitted = false;
            this.loading = false;
            if (response.isSuccess) {
              this.alertsService.showInfo(`Fund house ${this.isUpdate ? 'updated' : 'added'} successfully!.`, "Message", "");
              this.dialogRef.close(true);
            }
            else {
              this.alertsService.showInfo(response.message);
            }
          },
          error: error => {
            this.loading = false;
            error = error.message;
          }
        });
    }
    else {
      this.dialogRef.addPanelClass('invalid-modal');
    }
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }

  selectedLogoImage: File = null;

  onFileSelected(event: any): void {
    this.selectedLogoImage = event.target.files[0] ?? null;
    this.addFundHouseFormControl.logoImageFileName.setValue(this.selectedLogoImage.name);
  }
}
