import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { RegExPatterns } from '@app/_constants/regexPatterns';
import { AdminService } from '@app/_services/admin.service';
import { AlertsService } from '@app/_services/alerts.service';
import { CommonService } from '@app/_services/common.service';
import { FundHouseDto } from '@app/_services/dto/fund-house.dto';
import { FundDto } from '@app/_services/dto/fund.dto';
import { LocalStorageService } from '@app/_services/local-storage.service';
import { SessionStorageService } from '@app/_services/session-storage.service';
import { AppUserBaseComponent } from '@app/app-user-base.component';
import { first } from 'rxjs';

@Component({
  selector: 'app-add-update-fund',
  templateUrl: 'add-update-fund.component.html',
  styleUrls: ['add-update-fund.component.scss']
})
export class AddUpdateFundComponent extends AppUserBaseComponent implements OnInit {

  form!: FormGroup;
  fundHouseDto: FundDto = new FundDto();
  fundHouses: FundHouseDto[] = [];
  submitted = false;
  loading = false;
  error = '';
  selectedFundHouse: FundHouseDto = null;

  constructor(
    public dialogRef: MatDialogRef<AddUpdateFundComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FundDto,
    private fb: FormBuilder,
    private alertsService: AlertsService,
    private adminService: AdminService,
    localStorageService: LocalStorageService,
    activatedRoute: ActivatedRoute,
    commonService: CommonService,
    sessionStorageService: SessionStorageService,
  ) {

    super(activatedRoute, localStorageService, sessionStorageService, commonService);

    dialogRef.disableClose = false;
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
  }

  public get isUpdate(): boolean {
    return (this.data && this.data.id != null);
  }

  async ngOnInit() {
    this.form = this.fb.group({
      id: [null],
      fundHouseId: ['', Validators.required],
      shortName: ['', [Validators.required, Validators.pattern(RegExPatterns.validShortName)]],
      fullName: ['', Validators.required],
      sebiRegistrationNumber: ['', Validators.required],
      investmentManagerName: ['', Validators.required],
      sponsorName: ['', Validators.required],
      merchantBankerName: ['', Validators.required],
      legalAdvisorName: ['', Validators.required],
    }
    );

    if (this.isUpdate) {
      this.form.controls.id.setValue(this.data.id);
      this.form.controls.fundHouseId.setValue(this.data.fundHouseId);
      this.form.controls.shortName.setValue(this.data.shortName);
      this.form.controls.fullName.setValue(this.data.fullName);
      this.form.controls.sebiRegistrationNumber.setValue(this.data.sebiRegistrationNumber);
      this.form.controls.investmentManagerName.setValue(this.data.investmentManagerName);
      this.form.controls.sponsorName.setValue(this.data.sponsorName);
      this.form.controls.merchantBankerName.setValue(this.data.merchantBankerName);
      this.form.controls.legalAdvisorName.setValue(this.data.legalAdvisorName);

      this.selectedFundHouse = this.localStorageService.fundHouses.find(x => x.id = this.data.fundHouseId);
    }

    this.fundHouses = this.localStorageService.fundHouses;
  }

  get formControl() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {

      let fund = new FundDto();
      fund.id = this.formControl.id.value;
      fund.fundHouseId = this.formControl.fundHouseId.value;
      fund.shortName = this.formControl.shortName.value;
      fund.fullName = this.formControl.fullName.value;
      fund.sebiRegistrationNumber = this.formControl.sebiRegistrationNumber.value;
      fund.investmentManagerName = this.formControl.investmentManagerName.value;
      fund.sponsorName = this.formControl.sponsorName.value;
      fund.merchantBankerName = this.formControl.merchantBankerName.value;
      fund.legalAdvisorName = this.formControl.legalAdvisorName.value;

      this.loading = true;
      this.adminService.addUpdateFund(fund)
        .pipe(first())
        .subscribe({
          next: (response) => {
            this.submitted = false;
            this.loading = false;
            if (response.isSuccess) {
              this.alertsService.showInfo(`Fund ${this.isUpdate ? 'updated' : 'added'} successfully!.`, "Message", "");
              this.dialogRef.close(true);
            }
            else {
              this.alertsService.showError(response.message);
            }
          },
          error: error => {
            this.loading = false;
            error = error.message;
          }
        });
    }
    else {
      this.dialogRef.addPanelClass('invalid-modal');
    }
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
