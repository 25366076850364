import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { RoutePaths } from '@app/_constants';

import { AuthenticationService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.authenticationService.currentUser;
        if (user) {
            // check if route is restricted by role
            const { roles } = route.data;
            if (roles && !roles.includes(user.role)) {
                // role not authorized so redirect to home page
                this.router.navigate([RoutePaths.login]);
                return false;
            }
            // authorized so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate([RoutePaths.login], { queryParams: { returnUrl: state.url } });
        return false;
    }
}