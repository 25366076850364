import { Component } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { ResolverKeys } from './_constants/resolverKeys';
import { LocalStorageKeys } from './_constants/storage-keys';
import { CommonService } from './_services/common.service';
import { LocalStorageService } from './_services/local-storage.service';
import { SessionStorageService } from './_services/session-storage.service';
import { AppBaseComponent } from './app-base.component';
@Component({
    selector: 'app-user-base',
    template: `No template`
})
export class AppUserBaseComponent extends AppBaseComponent {
    constructor(
        private activatedRoute: ActivatedRoute,
        public readonly localStorageService: LocalStorageService,
        sessionStorageService: SessionStorageService,
        commonService: CommonService,
    ) {
        super(commonService, sessionStorageService);

        const documentTypesResponse = this.activatedRoute.snapshot.data[ResolverKeys.documentTypesResponse];
        const fundHousesResponse = this.activatedRoute.snapshot.data[ResolverKeys.fundHousesResponse];
        const masterDataResponse = this.activatedRoute.snapshot.data[ResolverKeys.masterDataResponse];

        if (!localStorageService.documentTypes) {
            localStorage.setItem(LocalStorageKeys.DOCUMENT_TYPES, JSON.stringify(documentTypesResponse.data));
        }

        if (!localStorageService.fundHouses) {
            localStorage.setItem(LocalStorageKeys.FUND_HOUSES, JSON.stringify(fundHousesResponse.data));
        }

        if (!localStorageService.masterData && masterDataResponse?.data) {
            localStorage.setItem(LocalStorageKeys.MASTER_DATA, JSON.stringify(masterDataResponse.data));
        }
    }
}