import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { toActiveInActiveString, toYesNoString } from '@app/_helpers/helper';
import { FundAdminListItemModel } from '@app/_models/user';
import { AdminService } from '@app/_services/admin.service';
import { AlertsService } from '@app/_services/alerts.service';
import { DataDtoFilter, DataDtoRange } from '@app/_services/dto/response.dto';
import { FundAdminListItemDto } from '@app/_services/dto/user.dto';
import { AddUpdateFundAdminComponent } from '../add-update-fund-admin';

@Component({
  selector: 'app-fund-admins',
  templateUrl: './fund-admins.component.html',
  styleUrls: ['./fund-admins.component.scss']
})
export class FundAdminsComponent implements OnInit, AfterViewInit {
  @ViewChild('filterInput') filterInput: ElementRef<HTMLElement>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('empTbSort') empTbSort = new MatSort();

  gridColumns: string[] = ['fullName', 'email', 'fundHouseFullName', 'fundFullName', 'emailConfirmedStatus', 'activeStatus', 'actions'];
  gridDatasource: MatTableDataSource<FundAdminListItemModel>;
  discription: string[];
  selectedStatusName: string;
  public range: DataDtoRange = new DataDtoRange();
  public filter: DataDtoFilter = new DataDtoFilter();
  public loading: boolean = false;

  statusName: any = [{ id: 0, name: '' }];
  pdialogConfig: MatDialogConfig;
  dialogWithForm: MatDialogRef<AddUpdateFundAdminComponent>;


  constructor(private dialogModel: MatDialog,
    public readonly adminService: AdminService,
    private readonly alertService: AlertsService
  ) {

    this.range.limit = 10;
  }

  public async ngOnInit(): Promise<void> {
    this.loadFundAdmins();
  }

  ngAfterViewInit(): void {
    if (this.gridDatasource) {
      this.gridDatasource.paginator = this.paginator;
      this.gridDatasource.sort = this.empTbSort;
    }
  }

  public async loadFundAdmins() {
    this.setLoading(true);
    this.adminService.getAllFundAdmins().then(response => {
      this.setLoading(false);
      if (response.isSuccess) {

        const listItems = response.data.map(x => {
          return {
            id: x.id,
            fundId: x.fundId,
            fundHouseId: x.fundHouseId,
            email: x.email,
            emailConfirmed: x.emailConfirmed,
            fullName: x.fullName,
            fundName: x.fundFullName,
            fundHouseName: x.fundHouseFullName,
            isActive: x.isActive,
            activeStatus: toActiveInActiveString(x.isActive),
            emailConfirmedStatus: toYesNoString(x.emailConfirmed)
          } as FundAdminListItemModel;
        });

        this.gridDatasource = new MatTableDataSource(listItems);
        this.gridDatasource.paginator = this.paginator;
        this.gridDatasource.sort = this.empTbSort;
        this.range.total = response.dataTotalCount;
      }

      const event = new KeyboardEvent('keyup', { 'bubbles': true });
      this.filterInput.nativeElement.dispatchEvent(event);
    });
  }

  public async onPage(event: PageEvent) {
    this.range.limit = event.pageSize;
    this.range.offset = event.pageIndex * event.pageSize;
    await this.loadFundAdmins();
  }

  getstatusName(statusId: number) {
    const data = this.statusName.filter((c: { id: number; }) => c.id == statusId);
    if (data.length > 0) {
      return data[0].name;
    }
    return null;
  }

  simpleDialog: MatDialogRef<AddUpdateFundAdminComponent>;

  addUserDialog() {
    let dialogRef = this.dialogModel.open(AddUpdateFundAdminComponent, {
      disableClose: false,
      //panelClass: 'fullscreen-dialog',
      width: '50%',
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadFundAdmins();
      }
    });

  }

  openEditDialog(fundAdmin: FundAdminListItemDto) {

    let dialogRef = this.dialogModel.open(AddUpdateFundAdminComponent, {
      disableClose: false,
      width: '50%',
      data: fundAdmin
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadFundAdmins();
      }
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.gridDatasource.filter = filterValue.trim().toLowerCase();

    if (this.gridDatasource.paginator) {
      this.gridDatasource.paginator.firstPage();
    }
  }

  resendEmailConfirmationLink(email: string) {
    this.adminService.sendUserEmailConfirmationLink(email).then(response => {
      if (response.isSuccess) {
        this.alertService.showInfo(`Email confirmation link sent to ${email}`);
      }
      else {
        this.alertService.showError(response.message);
      }
    });
  }

  private setLoading(isLoading: boolean) {
    this.loading = isLoading;
    if (isLoading) {
      this.gridDatasource = new MatTableDataSource([]);
    }
  }
}
