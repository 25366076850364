import { Injectable } from '@angular/core';
import { SessionStorageKeys } from '@app/_constants/storage-keys';

@Injectable({ providedIn: 'root' })
export class SessionStorageService {
    constructor() {
    }

    public get buildNumber(): string {
        return sessionStorage.getItem(SessionStorageKeys.BUILD_NUMBER);
    }
}