import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { RoutePaths } from './_constants';
import { AuthenticationService } from './_services';
import { CommonService } from './_services/common.service';
import { CurrentUserDto } from './_services/dto/user.dto';
import { LocalStorageService } from './_services/local-storage.service';
import { SessionStorageService } from './_services/session-storage.service';
import { AppBaseComponent } from './app-base.component';
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent extends AppBaseComponent implements OnInit {
    user?: CurrentUserDto | null;
    constructor(
        public authenticationService: AuthenticationService,
        public localStorageService: LocalStorageService,
        commonService: CommonService,
        sessionStorageService: SessionStorageService,
        activatedRoute: ActivatedRoute,
        private idle: Idle
    ) {

        super(commonService, sessionStorageService);

        this.authenticationService.user.subscribe(x => this.user = x);

        idle.setIdle(environment.idleTime);
        idle.setTimeout(environment.idleTime);
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onTimeout.subscribe(() => {
            this.logout();
        });
    }

    public get environment() {
        return environment;
    }

    public get routePaths() {
        return RoutePaths;
    }

    logout() {
        this.idle.stop();
        this.authenticationService.logout();
    }

    ngOnInit(): void {
        this.getScreenSize();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        setTimeout(() => {

            // const containerId = 'content-container';

            // const screenHeight = window.innerHeight;
            // const header = document.getElementById('main-header')?.clientHeight;
            // const footer = document.getElementById('footer')?.clientHeight;
            // const finalHeight = screenHeight - (header + footer) - 160;
            // const mainContainer = document.getElementById(containerId);
            // if (mainContainer && !isNaN(finalHeight)) {
            //     mainContainer.style.height = finalHeight + "px";
            // }
        }, 500);
    }
}