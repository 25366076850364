import { SchemeDateSettingDto } from "@app/_services/dto/scheme-date-setting.dto";
import { SITaxationSummaryDto } from "@app/_services/dto/si-taxation-summary.dto";
import { SchemeInvestorDataTableDefinition } from "@app/feature_components/scheme-investor-data-table/scheme-investor-data-table-definitiontype";
import { TableDefinitionUtility } from "../table-definition-helper";

export const getTaxationSummaryTableDefinition =
    (
        siTaxationSummaries: SITaxationSummaryDto[],
        schemeDateSetting: SchemeDateSettingDto,
        utility: TableDefinitionUtility
    ): SchemeInvestorDataTableDefinition => {

        const cellBorderCss = 'border:1pt #BEBEBE solid';

        let interestIncomeGrossTotal = siTaxationSummaries?.map(item => item?.interestIncomeGross).reduce((prev, next) => prev + next, 0);
        let interestIncomeTdsTotal = siTaxationSummaries?.map(item => item?.interestIncomeTDS).reduce((prev, next) => prev + next, 0);
        let interestIncomeNetTotal = siTaxationSummaries?.map(item => item?.interestIncomeNet).reduce((prev, next) => prev + next, 0);

        let stcGainLossGrossTotal = siTaxationSummaries?.map(item => item?.stcGainLossGross).reduce((prev, next) => prev + next, 0);
        let stcGainLossTdsTotal = siTaxationSummaries?.map(item => item?.stcGainLossTDS).reduce((prev, next) => prev + next, 0);
        let stcGainLossNetTotal = siTaxationSummaries?.map(item => item?.stcGainLossNet).reduce((prev, next) => prev + next, 0);

        let otherIncomeGrossTotal = siTaxationSummaries?.map(item => item?.otherIncomeGross).reduce((prev, next) => prev + next, 0);
        let otherIncomeTdsTotal = siTaxationSummaries?.map(item => item?.otherIncomeTDS).reduce((prev, next) => prev + next, 0);
        let otherIncomeNetTotal = siTaxationSummaries?.map(item => item?.otherIncomeNet).reduce((prev, next) => prev + next, 0);

        let totalGrossTotal = siTaxationSummaries?.map(item => item?.interestIncomeGross + item?.stcGainLossGross + item?.otherIncomeGross).reduce((prev, next) => prev + next, 0);
        let totalTdsTotal = siTaxationSummaries?.map(item => item?.interestIncomeTDS + item?.stcGainLossTDS + item?.otherIncomeTDS).reduce((prev, next) => prev + next, 0);
        let totalNetTotal = siTaxationSummaries?.map(item => item?.interestIncomeNet + item?.stcGainLossNet + item?.otherIncomeNet).reduce((prev, next) => prev + next, 0);


        let dataRows = siTaxationSummaries?.map(x => {
            return {
                css: `text-align:right;${cellBorderCss}`,
                data: [
                    utility.centerAligned(`${x.quarter} FY ${x.financialYearFrom} - ${x.financialYearTo} ${(x.note ? `&nbsp;<sup>${x.noteIndex}</sup>` : '')}`),
                    utility.currency(x.interestIncomeGross),
                    utility.currency(x.interestIncomeTDS),
                    utility.currency(x.interestIncomeNet),
                    utility.currency(x.stcGainLossGross),
                    utility.currency(x.stcGainLossTDS),
                    utility.currency(x.stcGainLossNet),
                    utility.currency(x.otherIncomeGross),
                    utility.currency(x.otherIncomeTDS),
                    utility.currency(x.otherIncomeNet),
                    utility.currency((x.interestIncomeGross + x.stcGainLossGross + x.otherIncomeGross)),
                    utility.currency((x.interestIncomeTDS + x.stcGainLossTDS + x.otherIncomeTDS)),
                    utility.currency((x.interestIncomeNet + x.stcGainLossNet + x.otherIncomeNet)),
                ]
            }
        });

        return {
            headerRowDefinitions:
                [
                    [
                        {
                            text: 'TAXATION SUMMARY' + (schemeDateSetting?.taxationSummaryNote ?
                                '&nbsp;<sup>'.concat(schemeDateSetting?.taxationSummaryNoteIndex?.toString()).concat('</sup>') :
                                ''),
                            colSpan: 13,
                            rowSpan: 1
                        }
                    ],
                    [
                        {
                            text: 'FINANCIAL<br/> YEAR',
                            colSpan: 1,
                            rowSpan: 2,
                            css: `${cellBorderCss};text-align:center`
                        },
                        {
                            text: 'INTEREST INCOME',
                            colSpan: 3,
                            rowSpan: 1,
                            css: `${cellBorderCss};text-align:center`
                        },
                        {
                            text: 'SHORT TERM CAPITAL GAINS / <br/>(LOSS)',
                            colSpan: 3,
                            rowSpan: 1,
                            css: `${cellBorderCss};text-align:center`
                        },
                        {
                            text: 'OTHER INCOME',
                            colSpan: 3,
                            rowSpan: 1,
                            css: `${cellBorderCss};text-align:center`
                        },
                        {
                            text: 'TOTAL',
                            colSpan: 3,
                            rowSpan: 1,
                            css: `${cellBorderCss};text-align:center`
                        }
                    ],
                    [
                        {
                            text: 'GROSS',
                            colSpan: 1,
                            rowSpan: 1,
                            css: `${cellBorderCss};text-align:center`
                        },
                        {
                            text: 'TDS',
                            colSpan: 1,
                            rowSpan: 1,
                            css: `${cellBorderCss};text-align:center`
                        },
                        {
                            text: 'NET',
                            colSpan: 1,
                            rowSpan: 1,
                            css: `${cellBorderCss};text-align:center`
                        },
                        {
                            text: 'GROSS',
                            colSpan: 1,
                            rowSpan: 1,
                            css: `${cellBorderCss};text-align:center`
                        },
                        {
                            text: 'TDS',
                            colSpan: 1,
                            rowSpan: 1,
                            css: `${cellBorderCss};text-align:center`
                        },
                        {
                            text: 'NET',
                            colSpan: 1,
                            rowSpan: 1,
                            css: `${cellBorderCss};text-align:center`
                        },
                        {
                            text: 'GROSS',
                            colSpan: 1,
                            rowSpan: 1,
                            css: `${cellBorderCss};text-align:center`
                        },
                        {
                            text: 'TDS',
                            colSpan: 1,
                            rowSpan: 1,
                            css: `${cellBorderCss};text-align:center`
                        },
                        {
                            text: 'NET',
                            colSpan: 1,
                            rowSpan: 1,
                            css: `${cellBorderCss};text-align:center`
                        },
                        {
                            text: 'GROSS',
                            colSpan: 1,
                            rowSpan: 1,
                            css: `${cellBorderCss};text-align:center`
                        },
                        {
                            text: 'TDS',
                            colSpan: 1,
                            rowSpan: 1,
                            css: `${cellBorderCss};text-align:center`
                        },
                        {
                            text: 'NET',
                            colSpan: 1,
                            rowSpan: 1,
                            css: `${cellBorderCss};text-align:center`
                        },
                    ]
                ],
            dataRows:
                [
                    ...dataRows
                ],
            dataColumnDefinitions: [
                { widthPercent: 20, css: `${cellBorderCss};text-align:center` },
                { widthPercent: 10, css: `${cellBorderCss};text-align:right` },
                { widthPercent: 10, css: `${cellBorderCss};text-align:right` },
                { widthPercent: 10, css: `${cellBorderCss};text-align:right` },
                { widthPercent: 10, css: `${cellBorderCss};text-align:right` },
                { widthPercent: 10, css: `${cellBorderCss};text-align:right` },
                { widthPercent: 10, css: `${cellBorderCss};text-align:right` },
                { widthPercent: 10, css: `${cellBorderCss};text-align:right` },
                { widthPercent: 10, css: `${cellBorderCss};text-align:right` },
                { widthPercent: 10, css: `${cellBorderCss};text-align:right` },
                { widthPercent: 10, css: `${cellBorderCss};text-align:right` },
                { widthPercent: 10, css: `${cellBorderCss};text-align:right` },
            ],
            footerRowDefinitions: [[
                {
                    text: 'Total',
                    colSpan: 1,
                    rowSpan: 1,
                    css: 'text-align:center;border:1pt #BEBEBE solid'
                },
                {
                    text: utility.currency(interestIncomeGrossTotal),
                    colSpan: 1,
                    rowSpan: 1,
                    css: cellBorderCss
                },
                {
                    text: utility.currency(interestIncomeTdsTotal),
                    colSpan: 1,
                    rowSpan: 1,
                    css: cellBorderCss
                },
                {
                    text: utility.currency(interestIncomeNetTotal),
                    colSpan: 1,
                    rowSpan: 1,
                    css: cellBorderCss
                },
                {
                    text: utility.currency(stcGainLossGrossTotal),
                    colSpan: 1,
                    rowSpan: 1,
                    css: cellBorderCss
                },
                {
                    text: utility.currency(stcGainLossTdsTotal),
                    colSpan: 1,
                    rowSpan: 1,
                    css: cellBorderCss
                },
                {
                    text: utility.currency(stcGainLossNetTotal),
                    colSpan: 1,
                    rowSpan: 1,
                    css: cellBorderCss
                },
                {
                    text: utility.currency(otherIncomeGrossTotal),
                    colSpan: 1,
                    rowSpan: 1,
                    css: cellBorderCss
                },
                {
                    text: utility.currency(otherIncomeTdsTotal),
                    colSpan: 1,
                    rowSpan: 1,
                    css: cellBorderCss
                },
                {
                    text: utility.currency(otherIncomeNetTotal),
                    colSpan: 1,
                    rowSpan: 1,
                    css: cellBorderCss
                },
                {
                    text: utility.currency(totalGrossTotal),
                    colSpan: 1,
                    rowSpan: 1,
                    css: cellBorderCss
                },
                {
                    text: utility.currency(totalTdsTotal),
                    colSpan: 1,
                    rowSpan: 1,
                    css: cellBorderCss
                },
                {
                    text: utility.currency(totalNetTotal),
                    colSpan: 1,
                    rowSpan: 1,
                    css: cellBorderCss
                }
            ]]
        }
    }