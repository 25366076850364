import { DtoBase } from "./base.dto";

export class DocumentDto extends DtoBase<number> {
  public fileName: string = '';
  public fileContentType: string = '';
  public documentType: string = '';
  public documentTypeId: number = null;
  public fundHouseId: number = null;
  public uploadedOn: Date = null;
  public uploadedByName: string = '';

  public updatedOn: Date = null;
  public updatedByName: string = '';

  public noticeDate: string = '';
  public dueDate: Date = null;
  public statementAsOnDate: Date = null;
  public statementIssueDate: Date = null;
  public distributionDate: Date = null;
  public newsletterDate: Date = null;
  public financialYear: string = null;
  public advanceTaxDate: Date = null;
  public canEditDocument: boolean = false;
  public isLinked: boolean = false;
}


export class DocumentListItemDto extends DocumentDto {
  public fundHouseShortName: string = '';
  public fundHouseFullName: string = '';
  public fundShortName: string = '';
  public fundFullName: string = '';
  public schemeShortName: string = '';
  public schemeFullName: string = '';
  public investorName: string = '';

  public linkedOn: Date = null;
  public linkedByName: string = '';
}
