<h2 mat-dialog-title>{{dialogTitle}}</h2>

<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>File</mat-label>
            <button type="button" mat-icon-button matPrefix
                (click)="fileInput.click()"><mat-icon>attach_file</mat-icon></button>
            <input type="text" readonly matInput formControlName="fileName">
            <input hidden
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                (change)="onFileSelected($event)" #fileInput type="file">
            <mat-error *ngIf="(formControl.fileName.touched || submitted) && formControl.fileName.errors?.required">
                File is required
            </mat-error>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button [disabled]="loading" type="submit" mat-raised-button color="primary"
            class="me-3 w-40 rounded-pill btn-ht">
            <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
            Submit</button>
        <button type="button" mat-raised-button color="warn" class="me-3 w-40 rounded-pill btn-ht"
            (click)="onNoClick()">Close</button>
    </mat-dialog-actions>
</form>