import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { InvestorDashboardDataDto } from './dto/investor-dashboard-data.dto';
import { IResponse } from './dto/response.dto';
import { SchemeInvestorDataOnDateDto } from './dto/scheme-investor-data-on-date.dto';

@Injectable({ providedIn: 'root' })
export class InvestorService {
    constructor(private http: HttpClient, private readonly apiService: ApiService,) {
    }

    public async getInvestorDashboardData(): Promise<IResponse<InvestorDashboardDataDto>> {
        return await this.apiService.get<IResponse<InvestorDashboardDataDto>>("/investor/dashboard/data");
    }

    public async getSchemeInvestorDataDate(schemeInvestorId: number, date: Date): Promise<IResponse<SchemeInvestorDataOnDateDto>> {
        return await this.apiService.get<IResponse<SchemeInvestorDataOnDateDto>>(`/investor/date/data?schemeInvestorId=${schemeInvestorId}&date=${date}`);
    }
}