<div *ngIf="!authenticationService.currentUser; else afterLoginTemplate">
  <div class="limiter">
    <div class="container-login100">
      <div class="row wrap-login100 px-5 d-flex justify-content-center flex-wrap">
        <div style="width: 100%;align-self: center;">
          <div class="kpfs-logo-container">
            <img src="assets/KPFS_LOGO.png" alt="IMG" class="img-fluid" />
          </div>
        </div>
        <div class="col-md-6 js-tilt d-none d-md-block" ngTilt>
          <img src="assets/img-01.png" alt="IMG" class="img-fluid" />
        </div>
        <div class="col-md-6 col-12">
          <mat-card *ngIf="!isLogin" class="py-4 px-5 shadow-none login-card">
            <mat-card-title class="text-center login-card-title">
              <div class="login-card-title-inner">
                <div *ngIf="fundHouseLogo" class="fund-house-logo-container-1">
                  <div class="fund-house-logo-container-2">
                    <img src="{{fundHouseLogo}}" alt="IMG" class="img-fluid" />
                  </div>
                </div>
                <div>{{fundHouseLogo?'Investor - ':''}}Login</div>
              </div>
            </mat-card-title>
            <mat-card-actions>
              <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="mt-3">
                <div class="">
                  <mat-form-field class="w-100 round-input" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input type="text" matInput formControlName="username" placeholder="Email" />
                    <mat-icon matPrefix>mail</mat-icon>
                    <mat-error *ngIf="submitted || f.username.errors?.required">
                      Email is required
                    </mat-error>
                    <mat-error *ngIf="f.username.touched && f.username.errors?.email">
                      Enter a valid email address
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="text-center mt-3">
                  <button [disabled]="loading" mat-raised-button color="primary" class="me-3 w-100 rounded-pill btn-ht">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
                    Login
                  </button>
                </div>
              </form>
            </mat-card-actions>
            <div *ngIf="error" class="alert alert-danger mt-3 mb-0" [innerHtml]="error">
            </div>
          </mat-card>
          <mat-card *ngIf="isLogin" class="py-4 px-5 shadow-none login-card">
            <mat-card-title class="text-center login-card-title">
              <div class="login-card-title-inner">
                <div *ngIf="fundHouseLogo" class="fund-house-logo-container-1">
                  <div class="fund-house-logo-container-2">
                    <img src="{{fundHouseLogo}}" alt="IMG" class="img-fluid" />
                  </div>
                </div>
                <div>Two Step Verification</div>
              </div>
            </mat-card-title>
            <div *ngIf="successMessage" class="alert alert-success mt-3 mb-0">
              {{ successMessage }}
            </div>
            <mat-card-actions>
              <form [formGroup]="codeForm" (ngSubmit)="onCodeSubmit()">
                <div class="mt-3">
                  <mat-form-field class="w-100 round-input" appearance="outline">
                    <mat-label>Code</mat-label>
                    <input type="text" matInput formControlName="code" placeholder="Code" maxlength="6" minlength="6" />
                    <mat-icon matPrefix>lock</mat-icon>
                    <mat-error *ngIf="submitted || c.code.errors?.required">
                      Code is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="text-center mt-3">
                  <button [disabled]="loading" mat-raised-button color="primary" class="me-3 w-100 rounded-pill btn-ht">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
                    Submit
                  </button>
                </div>
              </form>
            </mat-card-actions>
            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">
              {{ error }}
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #afterLoginTemplate>
  <div class="spinner">
  </div>
</ng-template>