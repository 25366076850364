import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DocumentTypeIds } from '@app/_constants/documentTypeIds';
import { AdminService } from '@app/_services/admin.service';
import { AlertsService } from '@app/_services/alerts.service';
import { CommonService } from '@app/_services/common.service';
import { DocumentTypeDto } from '@app/_services/dto/document-type.dto';
import { DocumentDto } from '@app/_services/dto/document.dto';
import { DropdownItemDto } from '@app/_services/dto/drop-down-item.dto';
import { FundHouseDto } from '@app/_services/dto/fund-house.dto';
import { FundDto } from '@app/_services/dto/fund.dto';
import { LocalStorageService } from '@app/_services/local-storage.service';
import { SessionStorageService } from '@app/_services/session-storage.service';
import { AppUserBaseComponent } from '@app/app-user-base.component';
import { first } from 'rxjs';

@Component({
  selector: 'app-add-update-document',
  templateUrl: 'add-update-document.component.html',
  styleUrls: ['add-update-document.component.scss']
})
export class AddUpdateDocumentComponent extends AppUserBaseComponent implements OnInit {
  form!: FormGroup;
  submitted = false;
  loading = false;
  error = '';
  hidePassword = true;

  fundHouses: FundHouseDto[] = [];
  funds: FundDto[] = [];
  schemes: DropdownItemDto[] = [];
  documentTypes: DocumentTypeDto[] = [];
  selectedFundHouse: FundHouseDto = null;

  constructor(
    public dialogRef: MatDialogRef<AddUpdateDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DocumentDto,
    private fb: FormBuilder,
    private alertsService: AlertsService,
    private adminService: AdminService,
    commonService: CommonService,
    sessionStorageService: SessionStorageService,
    localStorageService: LocalStorageService,
    activatedRoute: ActivatedRoute
  ) {

    super(activatedRoute, localStorageService, sessionStorageService, commonService);

    dialogRef.disableClose = false;
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
  }

  public get isUpdate(): boolean {
    return (this.data && this.data.id != null);
  }

  ngOnInit() {
    this.form = this.fb.group({
      id: [''],
      documentTypeId: ['', Validators.required],
      fundHouseId: ['', Validators.required],
      fileName: ['', this.isUpdate ? [] : Validators.required],
      noticeDate: [''],
      dueDate: [''],
      statementAsOnDate: [''],
      statementIssueDate: [''],
      distributionDate: [''],
      newsletterDate: [''],
      financialYear: [''],
      advanceTaxDate: ['']
    }
    );

    this.fundHouses = this.localStorageService.fundHouses;
    this.documentTypes = this.localStorageService.documentTypes;

    if (this.isUpdate) {
      this.form.controls.id.setValue(this.data.id);

      this.form.controls.documentTypeId.setValue(this.data.documentTypeId);
      this.form.controls.fundHouseId.setValue(this.data.fundHouseId);

      this.form.controls.noticeDate.setValue(this.data.noticeDate);
      this.form.controls.dueDate.setValue(this.data.dueDate);
      this.form.controls.statementAsOnDate.setValue(this.data.statementAsOnDate);
      this.form.controls.statementIssueDate.setValue(this.data.statementIssueDate);
      this.form.controls.distributionDate.setValue(this.data.distributionDate);
      this.form.controls.newsletterDate.setValue(this.data.newsletterDate);
      this.form.controls.financialYear.setValue(this.data.financialYear);
      this.form.controls.advanceTaxDate.setValue(this.data.advanceTaxDate);

      this.selectedFundHouse = this.localStorageService.fundHouses.find(x => x.id = this.data.fundHouseId);
    }
  }

  get formControls() {
    return this.form.controls;
  }

  public get isDrawdownReference(): boolean {
    return this.formControls.documentTypeId.value === DocumentTypeIds.DrawdownRefence;
  }

  public get isStatementOfAccount(): boolean {
    return this.formControls.documentTypeId.value === DocumentTypeIds.StatementOfAccount;
  }

  public get isDistribution(): boolean {
    return this.formControls.documentTypeId.value === DocumentTypeIds.Distribution;
  }

  public get isNewsletter(): boolean {
    return this.formControls.documentTypeId.value === DocumentTypeIds.Newsletter;
  }

  public get isAdvanceTax(): boolean {
    return this.formControls.documentTypeId.value === DocumentTypeIds.AdvanceTax;
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {

      let formData = new FormData();
      formData.append('id', this.formControls.id.value);
      formData.append('documentTypeId', this.formControls.documentTypeId.value);
      formData.append('fundHouseId', this.formControls.fundHouseId.value);
      for (let i = 0; i < this.selectedFiles?.length; i++) {
        formData.append('files', this.selectedFiles[i]);
      }

      if (this.formControls.noticeDate.value) {
        formData.append('noticeDate', formatDate(this.formControls.noticeDate.value, 'dd/MM/yyyy', 'en'));
      }

      if (this.formControls.dueDate.value) {
        formData.append('dueDate', formatDate(this.formControls.dueDate.value, 'dd/MM/yyyy', 'en'));
      }

      if (this.formControls.statementAsOnDate.value) {
        formData.append('statementAsOnDate', formatDate(this.formControls.statementAsOnDate.value, 'dd/MM/yyyy', 'en'));
      }

      if (this.formControls.statementIssueDate.value) {
        formData.append('statementIssueDate', formatDate(this.formControls.statementIssueDate.value, 'dd/MM/yyyy', 'en'));
      }

      if (this.formControls.distributionDate.value) {
        formData.append('distributionDate', formatDate(this.formControls.distributionDate.value, 'dd/MM/yyyy', 'en'));
      }

      if (this.formControls.newsletterDate.value) {
        formData.append('newsletterDate', formatDate(this.formControls.newsletterDate.value, 'dd/MM/yyyy', 'en'));
      }

      if (this.formControls.financialYear.value) {
        formData.append('financialYear', this.formControls.financialYear.value);
      }

      if (this.formControls.advanceTaxDate.value) {
        formData.append('advanceTaxDate', formatDate(this.formControls.advanceTaxDate.value, 'dd/MM/yyyy', 'en'));
      }


      this.loading = true;
      this.adminService.addUpdateDocument(formData)
        .pipe(first())
        .subscribe({
          next: (response) => {
            this.submitted = false;
            this.loading = false;
            if (response.isSuccess) {
              this.alertsService.showInfo(`Document ${this.isUpdate ? 'updated' : 'added'} successfully!.`, "Message", "");
              this.dialogRef.close(true);
            }
            else {
              this.alertsService.showInfo(response.message);
            }
          },
          error: error => {
            this.loading = false;
            error = error.message;
          }
        });
    }
    else {
      this.dialogRef.addPanelClass('invalid-modal');
    }
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }

  public onFundHouseChange(e: any) {
    this.commonService.getAllFunds(e.value).then(response => {
      if (response.isSuccess) {
        this.funds = response.data;
      }
    });
  }

  public onFundChange(e: any) {
    this.adminService.getSchemeDropdownItems(e.value, this.formControls.fundId.value).then(response => {
      if (response.isSuccess) {
        this.schemes = response.data;
      }
    });
  }

  selectedFiles: FileList;

  onFileSelected(event: any): void {
    this.selectedFiles = event.target.files ?? null;
    this.formControls.fileName.setValue(Array.from(this.selectedFiles).map(x => x.name).join(', '));
  }
}
