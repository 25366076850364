import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ImportDataType } from '@app/_enums/import-data-type';
import { toYesNoString } from '@app/_helpers/helper';
import { InvestorListItemModel } from '@app/_models/investor';
import { KpfsDatePipe } from '@app/_pipes/KpfsDateTimePipe';
import { AdminService } from '@app/_services/admin.service';
import { AlertsService } from '@app/_services/alerts.service';
import { DataDtoFilter, DataDtoRange } from '@app/_services/dto/response.dto';
import { SchemeInvestorDto } from '@app/_services/dto/scheme-investor.dto';
import { FeaturePermissionService } from '@app/_services/feature-permission.service';
import { environment } from '@environments/environment';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { AddUpdateSchemeInvestorComponent } from '../add-update-scheme-investor';
import { ImportDataComponent } from '../import-data';

@Component({
  selector: 'app-scheme-investors',
  templateUrl: './scheme-investors.component.html',
  styleUrls: ['./scheme-investors.component.scss']
})
export class SchemeInvestorsComponent implements OnInit, AfterViewInit {

  @ViewChild('filterInput') filterInput: ElementRef<HTMLElement>;

  exportSubmitLoading = false;

  gridColumns: string[] = [];

  userColumns: string[] =
    [
      'investorId',
      'schemeId',
      'investorName',
      'identityPanNumber',
      'identifier',
      'folioNo',
      'schemeShortName',
      'schemeFullName',
      'fundName',
      'fundHouseName'
    ];

  investorDetailsColumns: string[] =
    [
      'email1',
      'email2',
      'email3',
      'email4',
      'email5',
      'isCarryClass',
      'class',
      'forfeitAndTransferDate',
      'salutation',
      'modeOfHolding',
      'taxPanNumber',
      'contactNo',
      'address',
      'address1',
      'address2',
      'city',
      'state',
      'pincode',
      'bankAccountType',
      'bankName',
      'ifscCode',
      'micrCode',
      'accountNumber',
      'taxStatus',
      'setupFees',
      'managementFees',
      'operatingExpenses',
      'faceValue',
      'classOfUnits',
      'distributor',
      'hurdleRate',
      'catchUp',
      'additionalReturns',
      'depositorId',
      'depositorName',
      'clientId',
      'nomineeDetails',
      'currencyFormat',
      'sebiRegistrationNumber',
      'sebiInvestorType1',
      'sebiInvestorType2',
      'sebiInvestorType3',
      'capitalCommitment',
      'capitalContribution',
      'kpfsRecordStatus',
      'kpfsIncompleteRecordRemark'
    ];

  gridDatasource: MatTableDataSource<SchemeInvestorDto>;
  discription: string[];
  selectedStatusName: string;
  public range: DataDtoRange = new DataDtoRange();
  public filter: DataDtoFilter = new DataDtoFilter();
  public loading: boolean = false;
  public tableDisplay: string = 'table';

  statusName: any = [{ id: 0, name: '' }];
  pdialogConfig: MatDialogConfig;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('empTbSort') empTbSort = new MatSort();
  @ViewChild("exporter") exporter!: MatTableExporterDirective;

  constructor(private dialogModel: MatDialog,
    public readonly adminService: AdminService,
    public readonly featurePermissionService: FeaturePermissionService,
    private datePipe: KpfsDatePipe,
    private readonly alertsService: AlertsService
  ) {

    this.range.limit = 10;
    this.gridColumns = [...this.userColumns];
    this.gridColumns.push('actions');
  }

  public async ngOnInit(): Promise<void> {
    this.loadSchemeInvestors();
  }

  ngAfterViewInit(): void {
    if (this.gridDatasource) {
      this.gridDatasource.paginator = this.paginator;
      this.gridDatasource.sort = this.empTbSort;
    }
  }

  public get Environment() {
    return environment;
  }

  public async loadSchemeInvestors() {
    this.setLoading(true);
    this.adminService.getSchemeInvestors().then(response => {
      this.setLoading(false);
      if (response.isSuccess) {
        this.gridDatasource = new MatTableDataSource(response.data);
        this.gridDatasource.paginator = this.paginator;
        this.gridDatasource.sort = this.empTbSort;
        this.range.total = response.dataTotalCount;

        this.gridDatasource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'identifier': return item.investor.identifier;
            case 'identityPanNumber': return item.investor.identityPanNumber;
            case 'fundName': return item.fundFullName;
            case 'fundHouseName': return item.fundHouseFullName;
            default: return item[property];
          }
        };

        this.gridDatasource.filterPredicate = (data, filter: string) => {
          const accumulator = (currentTerm, key) => {
            if (key === 'investor') {
              return currentTerm + data.investor.identifier + data.investor.identityPanNumber;
            }
            else if (key === 'isCarryClass') {
              return currentTerm + toYesNoString(data.isCarryClass);
            }
            else if (key === 'forfeitAndTransferDate') {
              return currentTerm + (this.datePipe.transform(data.forfeitAndTransferDate));
            }

            return currentTerm + data[key];

          };

          const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
          const transformedFilter = filter.trim().toLowerCase();
          return dataStr.indexOf(transformedFilter) !== -1;
        };

        const event = new KeyboardEvent('keyup', { 'bubbles': true });
        this.filterInput.nativeElement.dispatchEvent(event);
      }
    });
  }

  public async onPage(event: PageEvent) {
    this.range.limit = event.pageSize;
    this.range.offset = event.pageIndex * event.pageSize;
    await this.loadSchemeInvestors();
  }

  getstatusName(statusId: number) {
    const data = this.statusName.filter((c: { id: number; }) => c.id == statusId);
    if (data.length > 0) {
      return data[0].name;
    }
    return null;
  }

  addUserDialog() {
    let dialogRef = this.dialogModel.open(AddUpdateSchemeInvestorComponent, {
      disableClose: false,
      width: '50%'
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadSchemeInvestors();
      }
    });

  }

  openEditDialog(investor: InvestorListItemModel) {

    let dialogRef = this.dialogModel.open(AddUpdateSchemeInvestorComponent, {
      disableClose: false,
      width: '50%',

      data: investor
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadSchemeInvestors();
      }
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.gridDatasource.filter = filterValue.trim().toLowerCase();

    if (this.gridDatasource.paginator) {
      this.gridDatasource.paginator.firstPage();
    }
  }

  toggleColumnsDisplay(e: any) {
    if (e.checked) {
      this.tableDisplay = 'block';
      this.gridColumns = [...this.userColumns, ... this.investorDetailsColumns];
    }
    else {
      this.tableDisplay = 'table';
      this.gridColumns = [...this.userColumns];
    }
    this.gridColumns.push('actions');
  }

  openSchemeInvestorLinkImportDialog() {
    let dialogRef = this.dialogModel.open(ImportDataComponent, {
      disableClose: false,
      width: '50%',
      data: ImportDataType.SchemeInvestorData
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadSchemeInvestors();
      }
    });
  }

  public exportToExcel() {
    this.exporter.exportTable('xlsx', { fileName: 'SchemeInvestors' });
    this.alertsService.showInfo('Export started successfully.');
  }

  // async exportToExcel() {

  //   this.exportSubmitLoading = true;
  //   (await this.adminService.exportData(null, ExportDataType.SchemeInvestorData))
  //     .subscribe(
  //       {
  //         next: (value: Blob) => {
  //           if (value.type === 'text/plain') {
  //             value.text().then(error => {
  //               this.alertsService.showError("No scheme investor to export!");
  //             });
  //           } else {
  //             this.alertsService.showInfo(`Data exported successfully!`);

  //             const blob = new Blob([value]);
  //             const downloadLink = document.createElement('a');
  //             downloadLink.href = URL.createObjectURL(blob);
  //             downloadLink.download = "Scheme_Investor_Data.xlsx";
  //             downloadLink.click();
  //             URL.revokeObjectURL(downloadLink.href);
  //           }
  //           this.exportSubmitLoading = false;
  //         },
  //         error: () => {
  //           this.exportSubmitLoading = false;
  //         }
  //       }
  //     );
  // }

  private setLoading(isLoading: boolean) {
    this.loading = isLoading;
    if (isLoading) {
      this.gridDatasource = new MatTableDataSource([]);
    }
  }
}
