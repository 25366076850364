import { ModelBase } from "./model-base";

export class SchemeListItemModel extends ModelBase {
  public shortName: string = '';
  public fullName: string = '';
  public schemeManager: string = '';
  public launchDate: Date;
  public currencyFormat: string = '';
  public date1: Date;
  public date2: Date;
  public date3: Date;
  public date4: Date;
  public date5: Date;
  public date6: Date;
  public showCommitmentAndContributionSummary: boolean;
  public showIncomeAndExpenseSummary: boolean;
  public showPerformance: boolean;
  public showTaxationSummary: boolean;
  public showCapitalTransactions: boolean;
  public showIncomeDistribution: boolean;
  public showTDSDistribution: boolean;
  public showOtherTransactions: boolean;


  public fundId: number = null;
  public fundHouseId: number = null;

  public fundHouseShortName: string = '';
  public fundHouseFullName: string = '';
  public fundShortName: string = '';
  public fundFullName: string = '';
}