export class RoutePaths {
    public static readonly login: string = 'login';
    public static readonly funds: string = 'funds';
    public static readonly fundHouses: string = 'fund-houses';
    public static readonly fundHouseAdmins: string = 'fund-house-admins';
    public static readonly investors: string = 'investors';
    public static readonly home: string = 'home';
    public static readonly confirmEmail: string = 'confirm-email/:token/:email';
    public static readonly documents: string = 'documents';
    public static readonly schemes: string = 'schemes';
    public static readonly fundAdmins: string = 'fund-admins';
    public static readonly schemeInvestors: string = 'scheme-investors';
    public static readonly profile: string = 'profile';
    public static readonly investorDashboard: string = 'investor-dashboard';
    public static readonly importExportData: string = 'import-export-data';
    public static readonly error: string = 'error';
}