import { DtoBase } from "./base.dto";
import { FundHouseDto } from "./fund-house.dto";

export class InvestorDto extends DtoBase<number> {
  public fullName: string = '';
  public identityPanNumber: string;
  public identifier: string;
  public isActive: boolean;

  public fundHouseId: number;
  public fundHouse: FundHouseDto;
}

export class AddUpdateInvestorDto extends DtoBase<number> {
  public fullName: string = '';
  public identityPanNumber: string;
  public identifier: string;
  public isActive: boolean;

  public fundHouseId: number;

}