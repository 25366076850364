import { KeyValue } from "@angular/common";
import { DtoBase } from "./base.dto";
import { InvestorDto } from "./investor.dto";
import { SchemeInvestorDataOnDateDto } from "./scheme-investor-data-on-date.dto";
import { SchemeDto } from "./scheme.dto";


export class SchemeInvestorBaseDto extends DtoBase<number> {
  public folioNo: string;
  public isCarryClass: boolean;
  public class: string;
  public forfeitAndTransferDate: Date;
  public salutation: string;
  public modeOfHolding?: number = null;
  public taxPanNumber: string;
  public contactNo: string;
  public address: string;
  public address1: string;
  public address2: string;
  public city: string;
  public state: string;
  public pincode: string;
  public bankAccountType?: string | null = null;
  public bankName: string;
  public ifscCode: string;
  public micrCode: string;
  public accountNumber: string;
  public taxStatus?: string | null = null;
  public setupFees: string;
  public managementFees: string;
  public operatingExpenses: string;
  public sebiInvestorType1?: string | null;
  public sebiInvestorType2?: string | null;
  public sebiInvestorType3?: string | null;
  public capitalCommitment: number;
  public capitalContribution: number;
  public kpfsRecordStatus?: string | null = null;
  public kpfsIncompleteRecordRemark?: string | null = null;
  public sebiRegistrationNumber?: string = null;
  public faceValue?: number = null;
  public classOfUnits?: string = null;
  public distributor?: string = null;
  public hurdleRate?: number = null;
  public catchUp?: number = null;
  public additionalReturns?: number = null;
  public depositorId?: string = null;
  public depositorName?: string = null;
  public clientId?: string = null;
  public nomineeDetails?: string = null;
  public currencyFormatCulture?: string | null;
  public currencyFormat?: string | null;

  public email1: string;
  public email2?: string = null;
  public email3?: string = null;
  public email4?: string = null;
  public email5?: string = null;

  public schemeId: number;
  public investorId: number;
}


export class SchemeInvestorDto extends SchemeInvestorBaseDto {

  public fundHouseShortName: string;
  public fundHouseFullName: string;
  public schemeShortName: string;
  public schemeFullName: string;
  public fundShortName: string;
  public fundFullName: string;
  public investorName: string;

  public fundHouseId: number;
  public fundId: number;

  public scheme: SchemeDto;
  public investor: InvestorDto;

  public schemeInvestorDataDates: SchemeInvestorDataOnDateDto[];
  public dates: KeyValue<string, Date>[];
}

export class AddUpdateSchemeInvestorDto extends SchemeInvestorBaseDto {
  public fundHouseId: number;
}