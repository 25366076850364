import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataDtoFilter, DataDtoRange } from '@app/_services/dto/response.dto';

import { ActivatedRoute } from '@angular/router';
import { ImportDataType } from '@app/_enums/import-data-type';
import { toYesNoString } from '@app/_helpers/helper';
import { KpfsDatePipe } from '@app/_pipes/KpfsDateTimePipe';
import { AlertsService } from '@app/_services/alerts.service';
import { CommonService } from '@app/_services/common.service';
import { DocumentTypeDto } from '@app/_services/dto/document-type.dto';
import { DocumentListItemDto } from '@app/_services/dto/document.dto';
import { FundHouseDto } from '@app/_services/dto/fund-house.dto';
import { FeaturePermissionService } from '@app/_services/feature-permission.service';
import { LocalStorageService } from '@app/_services/local-storage.service';
import { SessionStorageService } from '@app/_services/session-storage.service';
import { AppUserBaseComponent } from '@app/app-user-base.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@app/confirm-dialog/confirm-dialog.component';
import { environment } from '@environments/environment';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { AddUpdateDocumentComponent } from '../add-update-document';
import { ImportDataComponent } from '../import-data';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent extends AppUserBaseComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('gridSort') gridSort = new MatSort();
  @ViewChild("exporter") exporter!: MatTableExporterDirective;
  @ViewChild('filterInput') filterInput: ElementRef<HTMLElement>;

  gridDatasource: MatTableDataSource<DocumentListItemDto> = new MatTableDataSource([]);

  public range: DataDtoRange = new DataDtoRange();
  public filter: DataDtoFilter = new DataDtoFilter();
  public documentTypes: DocumentTypeDto[] = [];
  public selectedDocumentTypeId: number;
  public loading: boolean = false;

  constructor(private dialogModel: MatDialog,
    commonService: CommonService,
    sessionStorageService: SessionStorageService,
    private readonly alertService: AlertsService,
    public readonly featurePermissionService: FeaturePermissionService,
    localStorageService: LocalStorageService,
    activatedRoute: ActivatedRoute,
    private datePipe: KpfsDatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    super(activatedRoute, localStorageService, sessionStorageService, commonService);
    this.range.limit = 10;
  }

  public async ngOnInit(): Promise<void> {
    this.documentTypes = this.localStorageService.documentTypes;

    this.commonService.getLatestUploadedDocumentTypeId().then(response => {
      if (response.isSuccess) {
        this.selectedDocumentTypeId = response.data;
        this.loadDocuments();
      }
    });

    if (this.localStorageService.isInvestor) {
      this.commonService.getUserApplicableDocumentTypes().then(response => {
        if (response.isSuccess) {
          this.documentTypes = response.data;
        }
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.gridDatasource) {
      this.gridDatasource.paginator = this.paginator;
      this.gridDatasource.sort = this.gridSort;
    }
  }

  public get Environment() {
    return environment;
  }

  public get gridColumns(): string[] {
    let gridColumns: string[] = [
      'id',
      'fileName',
      'documentType',
      'uploadedOn',
      'uploadedByName',
      'updatedOn',
      'updatedByName',
      'fundHouseFullName',
      'isLinked',
      'linkedOn',
      'linkedByName',
      'investorName',
      'schemeShortName',
      'schemeFullName',
      'fundFullName'
    ];
    gridColumns.push('actions');

    return gridColumns;
  }

  public async loadDocuments() {
    this.setLoading(true);
    await this.commonService.getDocuments(this.selectedDocumentTypeId).then(response => {
      this.setLoading(false);
      if (response.isSuccess) {
        const documents = response.data;

        this.gridDatasource = new MatTableDataSource(documents);
        this.gridDatasource.paginator = this.paginator;
        this.gridDatasource.sort = this.gridSort;
        this.range.total = response.dataTotalCount;

        this.gridDatasource.filterPredicate = (data, filter: string) => {
          const accumulator = (currentTerm, key) => {
            if (key === 'isLinked') {
              return currentTerm + toYesNoString(data.isLinked);
            }
            else if (key === 'uploadedOn') {
              return currentTerm + (this.datePipe.transform(data.uploadedOn));
            }
            else if (key === 'updatedOn') {
              return currentTerm + (this.datePipe.transform(data.updatedOn));
            }
            else if (key === 'linkedOn') {
              return currentTerm + (this.datePipe.transform(data.linkedOn));
            }

            return currentTerm + data[key];

          };
          const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
          const transformedFilter = filter.trim().toLowerCase();
          return dataStr.indexOf(transformedFilter) !== -1;
        };

      }

      const event = new KeyboardEvent('keyup', { 'bubbles': true });
      this.filterInput.nativeElement.dispatchEvent(event);
    });
  }

  public async onPage(event: PageEvent) {
    this.range.limit = event.pageSize;
    this.range.offset = event.pageIndex * event.pageSize;
    await this.loadDocuments();
  }

  openAddDialog() {
    let dialogRef = this.dialogModel.open(AddUpdateDocumentComponent, {
      disableClose: false,
      width: '50%',
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadDocuments();
      }
    });

  }

  openEditDialog(fundHouse: FundHouseDto) {
    let dialogRef = this.dialogModel.open(AddUpdateDocumentComponent, {
      disableClose: false,
      width: '50%',
      data: fundHouse
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadDocuments();
      }
    });

  }

  openInvestorDocumentLinkDialog() {
    let dialogRef = this.dialogModel.open(ImportDataComponent, {
      disableClose: false,
      width: '50%',
      data: ImportDataType.InvestorDocumentLink
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadDocuments();
      }
    });
  }

  public async downloadDocument(documentId: number, fileName: string) {
    (await this.commonService.downloadDocument(documentId)).subscribe((response: Blob) => {

      const blob = new Blob([response]);
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = fileName;
      downloadLink.click();
      URL.revokeObjectURL(downloadLink.href);

      this.alertService.showInfo('Download has started successfully!');
    });
  }

  public async viewDocument(documentId: number, fileContentType: string) {
    (await this.commonService.downloadDocument(documentId)).subscribe((response: Blob) => {
      if (fileContentType === 'application/pdf') {
        const blob = new Blob([response], { type: fileContentType });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      }
      else {
        this.alertService.showError('Only PDF files can be viewed. Use download file option for other file types.');
      }
    });
  }

  public async deleteDocument(documentId: number) {
    const message = `Are you sure you want to delete this document permanently?`;

    const dialogData = new ConfirmDialogModel("Confirm Delete", message);

    const dialogRef = this.dialogModel.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(async deleteConfirm => {
      if (deleteConfirm) {
        await this.commonService.deleteDocument(documentId).then(response => {
          if (response.isSuccess) {
            this.alertService.showInfo("Document has been deleted successfully", "Message", "");
            this.loadDocuments();
          }
        });
      }
    });
  }

  public async documentTypeSelectionChange() {
    await this.loadDocuments();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.gridDatasource.filter = filterValue.trim().toLowerCase();

    if (this.gridDatasource.paginator) {
      this.gridDatasource.paginator.firstPage();
    }
  }

  public exportToExcel() {
    this.exporter.exportTable('xlsx', { fileName: 'Documents' });
  }

  private setLoading(isLoading: boolean) {
    this.loading = isLoading;
    if (isLoading) {
      this.gridDatasource = new MatTableDataSource([]);
    }
  }
}


