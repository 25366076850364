export const environment = {
    production: false,
    apiBaseUrl: 'http://dev.kpfs.co.in',
    apiUrl: 'https://devapi.kpfs.co.in/api',
    environmentType: 'development',
    dateFormat: 'DD/MM/YYYY',
    dateTimeFormat: 'DD/MM/YYYY hh:mm:ss A',
    idleTime: 30000,
    idleTimeout: 30000
};
