<h2 mat-dialog-title>{{isUpdate?'Update':'Add'}} Fund House</h2>
<form class="form" [formGroup]="addFundHouseForm" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Short Name</mat-label>
            <input type="text" matInput formControlName="shortName" placeholder="Short Name" maxlength="50">
            <mat-error
                *ngIf="(addFundHouseFormControl.shortName.touched || submitted) && addFundHouseFormControl.shortName.errors?.required">
                Short Name is required
            </mat-error>
            <mat-error
                *ngIf="(addFundHouseFormControl.shortName.touched || submitted) && addFundHouseFormControl.shortName.errors?.pattern">
                Short Name can only consists of alphabets, numbers and _
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Full Name</mat-label>
            <input type="text" matInput formControlName="fullName" placeholder="Full Name" maxlength="100">
            <mat-error
                *ngIf="(addFundHouseFormControl.fullName.touched || submitted) && addFundHouseFormControl.fullName.errors?.required">
                Full Name is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Logo Image</mat-label>
            <button type="button" mat-icon-button matPrefix
                (click)="fileInput.click()"><mat-icon>attach_file</mat-icon></button>
            <input type="text" readonly matInput formControlName="logoImageFileName">
            <input hidden accept="image/png" (change)="onFileSelected($event)" #fileInput type="file">
            <mat-error
                *ngIf="(addFundHouseFormControl.logoImageFileName.touched || submitted) && addFundHouseFormControl.logoImageFileName.errors?.required">
                Logo Image is required
            </mat-error>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <!-- <button mat-button [mat-dialog-close]="true" tabindex="1">Install</button>
        <button mat-button mat-dialog-close tabindex="-1">Cancel</button> -->
        <button [disabled]="loading" type="submit" mat-raised-button color="primary"
            class="me-3 w-40 rounded-pill btn-ht">
            <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
            {{isUpdate?'Update':'Submit'}}</button>
        <button type="button" mat-raised-button color="warn" class="me-3 w-40 rounded-pill btn-ht"
            (click)="onNoClick()">Close</button>
    </mat-dialog-actions>
</form>