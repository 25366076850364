import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { FormDataKeys } from "@app/_constants/form-data-keys";
import { AdminService } from "@app/_services/admin.service";
import { AlertsService } from "@app/_services/alerts.service";
import { CommonService } from "@app/_services/common.service";
import { DropdownItemDto } from "@app/_services/dto/drop-down-item.dto";
import { ExportDataTypeDto } from "@app/_services/dto/export-data-type.dto";
import { FundHouseDto } from "@app/_services/dto/fund-house.dto";
import { FundDto } from "@app/_services/dto/fund.dto";
import { ImportDataTypeDto } from "@app/_services/dto/import-data-type.dto";
import { LocalStorageService } from "@app/_services/local-storage.service";
import { SessionStorageService } from "@app/_services/session-storage.service";
import { AppUserBaseComponent } from "@app/app-user-base.component";

@Component({
    selector: 'app-import-export-data',
    templateUrl: './import-export-data.html',
    styleUrls: ['./import-export-data.scss']
})
export class ImportExportDataComponent extends AppUserBaseComponent implements OnInit {

    @ViewChild('fileInput') fileInput: ElementRef;

    importDataTypes: ImportDataTypeDto[];
    exportDataTypes: ExportDataTypeDto[];
    selectedImportDataType: ImportDataTypeDto = null;
    selectedExportDataType: ExportDataTypeDto = null;

    fundHouses: FundHouseDto[] = [];
    funds: FundDto[] = [];
    schemes: DropdownItemDto[] = [];

    importForm!: FormGroup;
    importSubmitted = false;
    importSubmitLoading = false;

    exportForm!: FormGroup;
    exportSubmitted = false;
    exportSubmitLoading = false;

    error = '';

    constructor(
        localStorageService: LocalStorageService,
        activatedRoute: ActivatedRoute,
        private adminService: AdminService,
        commonService: CommonService,
        sessionStorageService: SessionStorageService,
        private alertsService: AlertsService,
        private fb: FormBuilder,
    ) {
        super(activatedRoute, localStorageService, sessionStorageService, commonService);

    }

    public async ngOnInit(): Promise<void> {

        this.adminService.getImportDataTypes().then(response => {
            if (response.isSuccess) {
                this.importDataTypes = response.data;
            }
        });

        this.adminService.getExportDataTypes().then(response => {
            if (response.isSuccess) {
                this.exportDataTypes = response.data;
            }
        });

        this.importForm = this.fb.group({
            fileName: ['', Validators.required],
            importDataTypeId: [0, Validators.required],
        }
        );

        this.exportForm = this.fb.group({
            fundHouseId: ['', Validators.required],
            fundId: ['', Validators.required],
            schemeId: ['', Validators.required],
            exportDataTypeId: [0, Validators.required],
        }
        );

        this.fundHouses = this.localStorageService.fundHouses;
    }

    get importFormControl() {
        return this.importForm.controls;
    }

    get exportFormControl() {
        return this.exportForm.controls;
    }

    async onImportSubmit() {
        this.importSubmitted = true;
        if (this.importForm.valid) {

            let formData = new FormData();
            formData.append(FormDataKeys.FILE, this.selectedFile);
            formData.append(FormDataKeys.DATA_TYPE, this.selectedImportDataType.dataTypeId.toString());

            this.importSubmitLoading = true;

            (await this.adminService.importData(formData))
                .subscribe(
                    {
                        next: (value: Blob) => {
                            if (value.size === 0) {
                                this.alertsService.showInfo(`Data imported successfully!.`, "Message", "");
                            }
                            else {
                                if (value.type === 'text/plain') {
                                    value.text().then(error => {
                                        this.alertsService.showError(error);
                                        this.selectedFile = null;
                                        this.importFormControl.fileName.setValue('');
                                        this.importFormControl.fileName.markAsUntouched();
                                    });
                                } else {
                                    this.alertsService.showError(`Errors in records! Error file has been downloaded. Please correct the errors and re-upload.`);
                                    const blob = new Blob([value]);
                                    const downloadLink = document.createElement('a');
                                    downloadLink.href = URL.createObjectURL(blob);
                                    downloadLink.download = "Error.xlsx";
                                    downloadLink.click();
                                    URL.revokeObjectURL(downloadLink.href);

                                    this.selectedFile = null;
                                    this.importFormControl.fileName.setValue('');
                                    this.importFormControl.fileName.markAsUntouched();
                                }
                            }

                            this.importSubmitLoading = false;
                        },
                        error: () => {
                            this.importSubmitLoading = false;
                        }
                    }
                );
        }
    }

    selectedFile: File = null;

    onFileSelected(event: any): void {
        this.selectedFile = event.target.files[0] ?? null;
        this.importFormControl.fileName.setValue(this.selectedFile.name);

        (event.target as HTMLInputElement).value = '';
    }

    async onExportSubmit() {

        this.exportSubmitted = true;
        if (this.exportForm.valid) {
            this.exportSubmitLoading = true;
            (await this.adminService.exportData(this.exportFormControl.schemeId.value, this.selectedExportDataType.dataTypeId))
                .subscribe(
                    {
                        next: (value: Blob) => {
                            if (value.type === 'text/plain') {
                                value.text().then(error => {
                                    this.alertsService.showError(error);
                                });
                            } else {
                                this.alertsService.showInfo(`Data exported successfully!`);

                                const blob = new Blob([value]);
                                const downloadLink = document.createElement('a');
                                downloadLink.href = URL.createObjectURL(blob);
                                downloadLink.download = this.selectedExportDataType.dataTypeText.replace(' ', '_').concat('.xlsx');
                                downloadLink.click();
                                URL.revokeObjectURL(downloadLink.href);
                            }
                            this.exportSubmitLoading = false;
                        },
                        error: () => {
                            this.exportSubmitLoading = false;
                        }
                    }
                );
        }
    }

    public onFundHouseChange(e: any) {
        this.funds = [];
        this.schemes = [];

        this.exportFormControl.fundId.setValue(null);
        this.exportFormControl.fundId.markAsUntouched();
        this.exportFormControl.schemeId.setValue(null);
        this.exportFormControl.schemeId.markAsUntouched();

        this.commonService.getAllFunds(e.value).then(response => {
            if (response.isSuccess) {
                this.funds = response.data;
            }
        });
    }

    public onFundChange(e: any) {
        this.exportFormControl.schemeId.setValue(null);
        this.exportFormControl.schemeId.markAsUntouched();

        this.adminService.getSchemeDropdownItems(this.exportFormControl.fundHouseId.value, e.value).then(response => {
            if (response.isSuccess) {
                this.schemes = response.data;
            }
        });
    }
}