import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FundHouseAdminListItemModel } from '@app/_models/user';
import { AlertsService } from '@app/_services/alerts.service';
import { CommonService } from '@app/_services/common.service';
import { FundHouseDto } from '@app/_services/dto/fund-house.dto';
import { AddFundHouseAdminDto, UpdateFundHouseAdminDto } from '@app/_services/dto/registration.dto';
import { LocalStorageService } from '@app/_services/local-storage.service';
import { RegistrationService } from '@app/_services/registration.service';
import { SessionStorageService } from '@app/_services/session-storage.service';
import { AppUserBaseComponent } from '@app/app-user-base.component';
import { first } from 'rxjs';

@Component({
  selector: 'app-add-update-fund-house-admin',
  templateUrl: 'add-update-fund-house-admin.component.html',
  styleUrls: ['add-update-fund-house-admin.component.scss']
})
export class AddUpdateFundHouseAdminComponent extends AppUserBaseComponent implements OnInit {

  form!: FormGroup;
  addUserDto: AddFundHouseAdminDto = new AddFundHouseAdminDto();
  submitted = false;
  loading = false;
  error = '';
  fundHouses: FundHouseDto[] = [];
  selectedFundHouse: FundHouseDto;

  constructor(
    public dialogRef: MatDialogRef<AddUpdateFundHouseAdminComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FundHouseAdminListItemModel,
    private fb: FormBuilder,
    private alertsService: AlertsService,
    private readonly registrationService: RegistrationService,
    localStorageService: LocalStorageService,
    activatedRoute: ActivatedRoute,
    commonService: CommonService,
    sessionStorageService: SessionStorageService
  ) {

    super(activatedRoute, localStorageService, sessionStorageService, commonService);

    dialogRef.disableClose = false;
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
  }

  ngOnInit() {

    this.form = this.fb.group({
      id: [null],
      fullName: ['', Validators.required],
      email: ['', this.isUpdate ? [] : [Validators.required, Validators.email]],
      fundHouseId: ['', Validators.required],
      isActive: ['', this.isUpdate ? [Validators.required] : []]
    }
    );

    if (this.isUpdate) {
      this.form.controls.id.setValue(this.data.id);
      this.form.controls.fullName.setValue(this.data.fullName);
      this.form.controls.email.setValue(this.data.email);
      this.form.controls.fundHouseId.setValue(this.data.fundHouseId);
      this.form.controls.isActive.setValue(this.data.isActive);

      this.selectedFundHouse = this.localStorageService.fundHouses.find(x => x.id = this.data.fundHouseId);
    }

    this.fundHouses = this.localStorageService.fundHouses;
  }

  get registerFormControl() {
    return this.form.controls;
  }

  public get isUpdate(): boolean {
    return (this.data && this.data.id != null);
  }

  onSubmit() {
    if (this.form.valid) {
      if (this.isUpdate) {
        this.updateFundHouseAdmin();
      }
      else {
        this.addNewFundHouseAdmin();
      }
    }
    else {
      this.dialogRef.addPanelClass('invalid-modal');
    }
  }

  private addNewFundHouseAdmin() {
    this.addUserDto = new AddFundHouseAdminDto();
    this.addUserDto.fullName = this.registerFormControl.fullName.value;
    this.addUserDto.email = this.registerFormControl.email.value;
    this.addUserDto.fundHouseId = this.registerFormControl.fundHouseId.value;

    this.loading = true;
    this.registrationService.addFundHouseAdmin(this.addUserDto)
      .pipe(first())
      .subscribe({
        next: (response) => {
          this.submitted = false;
          this.loading = false;
          if (response.isSuccess) {
            this.alertsService.showInfo("User added and confirmation link has been sent to the email.", "Message", "");
            this.dialogRef.close(true);
          }
          else
            this.alertsService.showError(response.message);
        },
        error: error => {
          this.loading = false;
          error = error.message;
        }
      });
  }

  private updateFundHouseAdmin() {
    let fundHouseAdmin = new UpdateFundHouseAdminDto();
    fundHouseAdmin.fullName = this.registerFormControl.fullName.value;
    fundHouseAdmin.email = this.registerFormControl.email.value;
    fundHouseAdmin.isActive = this.registerFormControl.isActive.value;

    this.loading = true;
    this.registrationService.updateFundHouseAdmin(fundHouseAdmin)
      .pipe(first())
      .subscribe({
        next: (response) => {
          this.submitted = false;
          this.loading = false;
          if (response.isSuccess) {
            this.alertsService.showInfo("User updated successfully!.", "Message", "");
            this.dialogRef.close(true);
          }
          else
            this.alertsService.showError(response.message);
        },
        error: error => {
          this.loading = false;
          error = error.message;
        }
      });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
