<div style="background-color: white;">
    <div class="mt-3">
        <div class="d-flex align-items-center justify-content-between bg-white py-2 px-3 main-div">
            <h2 class="dialog-custom-header-text m-0">Import/Export Data</h2>
            <div class="d-flex align-items-center gap-3">
                <div class="user-grid-head">

                </div>
            </div>
        </div>

        <mat-card>
            <mat-card-content>
                <div fxLayout="column">
                    <mat-tab-group dynamicHeight style="min-height:50%">
                        <mat-tab label="Import">
                            <form class="w-50" class="form" [formGroup]="importForm" (ngSubmit)="onImportSubmit()">
                                <mat-card>
                                    <mat-card-content>
                                        <mat-form-field class="w-50" appearance="outline">
                                            <mat-label>Data Type</mat-label>
                                            <mat-select [(ngModel)]="selectedImportDataType"
                                                formControlName="importDataTypeId">
                                                <mat-option *ngFor="let d of importDataTypes" [value]="d">
                                                    {{d.dataTypeText}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="(importFormControl.importDataTypeId.touched || importSubmitted) && importFormControl.importDataTypeId.errors?.required">
                                                Data type is required
                                            </mat-error>
                                        </mat-form-field>
                                        <a class="ms-2"
                                            [attr.href]="selectedImportDataType ? selectedImportDataType?.templateFilePath : null"
                                            [class.disabled]="!selectedImportDataType">Document Template</a>
                                        <mat-form-field class="w-50" appearance="outline">
                                            <mat-label>File</mat-label>
                                            <button type="button" mat-icon-button matPrefix
                                                (click)="fileInput.click()"><mat-icon>attach_file</mat-icon></button>
                                            <input type="text" readonly matInput formControlName="fileName">
                                            <input hidden
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                (change)="onFileSelected($event)" #fileInput type="file">
                                            <mat-error
                                                *ngIf="(importFormControl.fileName.touched || importSubmitted) && importFormControl.fileName.errors?.required">
                                                File is required
                                            </mat-error>
                                        </mat-form-field>
                                    </mat-card-content>
                                    <mat-card-actions>
                                        <button [disabled]="importSubmitLoading" type="submit" mat-raised-button
                                            color="primary" class="me-3 w-40 rounded-pill btn-ht">
                                            <span *ngIf="importSubmitLoading"
                                                class="spinner-border spinner-border-sm me-1"></span>
                                            Import<mat-icon class="material-icons-outlined">upload</mat-icon></button>
                                    </mat-card-actions>
                                </mat-card>
                            </form>
                        </mat-tab>
                        <mat-tab label="Export">
                            <form class="w-50" class="form" [formGroup]="exportForm" (ngSubmit)="onExportSubmit()">
                                <mat-card>
                                    <mat-card-content class="d-flex flex-column">
                                        <mat-form-field class=" w-50" appearance="outline">
                                            <mat-label>Fund House</mat-label>
                                            <mat-select formControlName="fundHouseId"
                                                (selectionChange)="onFundHouseChange($event)">
                                                <mat-option *ngFor="let fh of fundHouses" [value]="fh.id">
                                                    {{fh.combinedName}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="(exportFormControl.fundHouseId.touched || exportSubmitted) && exportFormControl.fundHouseId.errors?.required">
                                                Fund house is required
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="w-50" appearance="outline">
                                            <mat-label>Fund</mat-label>
                                            <mat-select (selectionChange)="onFundChange($event)"
                                                formControlName="fundId">
                                                <mat-option *ngFor="let f of funds" [value]="f.id">
                                                    {{f.combinedName}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="(exportFormControl.fundId.touched || exportSubmitted) && exportFormControl.fundId.errors?.required">
                                                Fund is required
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="w-50" appearance="outline">
                                            <mat-label>Scheme</mat-label>
                                            <mat-select formControlName="schemeId">
                                                <mat-option *ngFor="let s of schemes" [value]="s.value">
                                                    {{s.text}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="(exportFormControl.schemeId.touched || exportSubmitted) && exportFormControl.schemeId.errors?.required">
                                                Scheme is required
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="w-50" appearance="outline">
                                            <mat-label>Data Type</mat-label>
                                            <mat-select [(ngModel)]="selectedExportDataType"
                                                formControlName="exportDataTypeId">
                                                <mat-option *ngFor="let d of exportDataTypes" [value]="d">
                                                    {{d.dataTypeText}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="(exportFormControl.exportDataTypeId.touched || exportSubmitted) && exportFormControl.exportDataTypeId.errors?.required">
                                                Data type is required
                                            </mat-error>
                                        </mat-form-field>
                                    </mat-card-content>
                                    <mat-card-actions>
                                        <button [disabled]="exportSubmitLoading" type="submit" mat-raised-button
                                            color="primary" class="me-3 rounded-pill btn-ht">
                                            <span *ngIf="exportSubmitLoading"
                                                class="spinner-border spinner-border-sm me-1"></span>
                                            Export <mat-icon
                                                class="material-icons-outlined">download</mat-icon></button>
                                    </mat-card-actions>
                                </mat-card>
                            </form>
                        </mat-tab>
                    </mat-tab-group>

                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>