import { SchemeDateSettingDto } from "@app/_services/dto/scheme-date-setting.dto";
import { SICommitmentContributionDto } from "@app/_services/dto/si-commitment-contribution.dto";
import { DataRow, defaultColumnDefinition, SchemeInvestorDataTableDefinition } from "@app/feature_components/scheme-investor-data-table/scheme-investor-data-table-definitiontype";
import { TableDefinitionUtility } from "../table-definition-helper";

export const getCommitmentContributionSummaryTableDefinition = (
    siCommitmentContributions: SICommitmentContributionDto[],
    schemeDateSetting: SchemeDateSettingDto,
    utility: TableDefinitionUtility
): SchemeInvestorDataTableDefinition => {

    const dataRows = siCommitmentContributions?.map(x => {
        return {
            data: [
                x.title.concat(x.note ? '&nbsp;<sup>'.concat(x.noteIndex?.toString()).concat('</sup>') : ''),
                x.legend,
                utility.currency(x.amount),
                utility.number(x.units),
                utility.number(x.commitmentPercent).concat(x.commitmentPercent ? '%' : '')
            ]
        } as DataRow
    });

    return {
        headerRowDefinitions:
            [
                [
                    defaultColumnDefinition('COMMITMENT AND CONTRIBUTION SUMMARY' +
                        (schemeDateSetting?.commitmentAndContributionSummaryNote ?
                            '&nbsp;<sup>'.concat(schemeDateSetting?.commitmentAndContributionSummaryNoteIndex?.toString()).concat('</sup>') :
                            '')),
                    { text: '', colSpan: 1, rowSpan: 1, css: 'text-align:right' },
                    { text: 'AMOUNT (INR)', colSpan: 1, rowSpan: 1, css: 'text-align:right' },
                    { text: 'UNITS', colSpan: 1, rowSpan: 1, css: 'text-align:right' },
                    { text: '% OF COMMITMENT', colSpan: 1, rowSpan: 1, css: 'text-align:right' }
                ]
            ],
        dataColumnDefinitions: [
            { widthPercent: 30 },
            { widthPercent: 10, css: 'white-space:nowrap;text-align:center' },
            { widthPercent: 10, css: 'text-align:right' },
            { widthPercent: 10, css: 'text-align:right' },
            { widthPercent: 10, css: 'text-align:right' }
        ],
        dataRows: [
            ...dataRows
        ]
    }
}