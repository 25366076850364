import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { AddUpdateInvestorDto } from './dto/investor.dto';
import { AddFundAdminDto, AddFundHouseAdminDto, RegisterUserDto, UpdateFundAdminDto, UpdateFundHouseAdminDto } from './dto/registration.dto';
import { IResponse } from './dto/response.dto';

@Injectable({ providedIn: 'root' })
export class RegistrationService {
    constructor(private http: HttpClient) {
    }

    registerUser(dto: RegisterUserDto) {
        return this.http.post<IResponse<boolean>>(`${environment.apiUrl}/authentication/register`, dto)
            .pipe(map((response) => {
                return response;
            }));
    }

    confirmEmail(email: string, token: string) {
        return this.http.get<IResponse<boolean>>(`${environment.apiUrl}/authentication/confirm-email?token=${token}&email=${email}`)
            .pipe(map((response) => {
                return response;
            }));
    }

    addFundHouseAdmin(dto: AddFundHouseAdminDto) {
        return this.http.post<IResponse<boolean>>(`${environment.apiUrl}/admin/user/fund-house-admin/add`, dto)
            .pipe(map((response) => {
                return response;
            }));
    }

    updateFundHouseAdmin(dto: UpdateFundHouseAdminDto) {
        return this.http.post<IResponse<boolean>>(`${environment.apiUrl}/admin/user/fund-house-admin/update`, dto)
            .pipe(map((response) => {
                return response;
            }));
    }

    addFundAdmin(dto: AddFundAdminDto) {
        return this.http.post<IResponse<boolean>>(`${environment.apiUrl}/admin/user/fund-admin/add`, dto)
            .pipe(map((response) => {
                return response;
            }));
    }

    updateFundAdmin(dto: UpdateFundAdminDto) {
        return this.http.post<IResponse<boolean>>(`${environment.apiUrl}/admin/user/fund-admin/update`, dto)
            .pipe(map((response) => {
                return response;
            }));
    }


    addUpdateInvestor(dto: AddUpdateInvestorDto) {
        return this.http.post<IResponse<boolean>>(`${environment.apiUrl}/admin/user/investor/add-update`, dto)
            .pipe(map((response) => {
                return response;
            }));
    }
}