import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageKeys } from '@app/_constants/storage-keys';
import { AdminService } from '@app/_services/admin.service';
import { CommonService } from '@app/_services/common.service';
import { FundHouseDto } from '@app/_services/dto/fund-house.dto';
import { DataDtoFilter, DataDtoRange } from '@app/_services/dto/response.dto';
import { LocalStorageService } from '@app/_services/local-storage.service';
import { SessionStorageService } from '@app/_services/session-storage.service';
import { AppUserBaseComponent } from '@app/app-user-base.component';
import { AddUpdateFundHouseComponent } from '../add-update-fund-house';

@Component({
  selector: 'app-fund-houses',
  templateUrl: './fund-houses.component.html',
  styleUrls: ['./fund-houses.component.scss']
})
export class FundHousesComponent extends AppUserBaseComponent implements OnInit, AfterViewInit {

  @ViewChild('filterInput') filterInput: ElementRef<HTMLElement>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('gridSort') gridSort = new MatSort();

  gridColumns: string[] = ['id', 'shortName', 'fullName', 'logo', 'actions'];
  gridDatasource: MatTableDataSource<FundHouseDto>;
  public loading: boolean = false;
  public range: DataDtoRange = new DataDtoRange();
  public filter: DataDtoFilter = new DataDtoFilter();


  constructor(private dialogModel: MatDialog,
    public readonly adminService: AdminService,
    localStorageService: LocalStorageService,
    activatedRoute: ActivatedRoute,
    commonService: CommonService,
    sessionStorageService: SessionStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    super(activatedRoute, localStorageService, sessionStorageService, commonService);
    this.range.limit = 10;
  }

  public async ngOnInit(): Promise<void> {
    this.loadFundHouses();
  }

  ngAfterViewInit(): void {
    if (this.gridDatasource) {
      this.gridDatasource.paginator = this.paginator;
      this.gridDatasource.sort = this.gridSort;
    }
  }

  public async loadFundHouses() {
    this.setLoading(true);
    await this.commonService.getAllFundHouses().then(response => {
      this.setLoading(false);
      if (response.isSuccess) {
        localStorage.setItem(LocalStorageKeys.FUND_HOUSES, JSON.stringify(response.data));

        this.gridDatasource = new MatTableDataSource(this.localStorageService.fundHouses);
        this.gridDatasource.paginator = this.paginator;
        this.gridDatasource.sort = this.gridSort;
        this.range.total = this.localStorageService.fundHouses.length;

        const event = new KeyboardEvent('keyup', { 'bubbles': true });
        this.filterInput.nativeElement.dispatchEvent(event);
      }
    });
  }

  public async onPage(event: PageEvent) {
    this.range.limit = event.pageSize;
    this.range.offset = event.pageIndex * event.pageSize;
    await this.loadFundHouses();
  }

  openAddDialog() {
    let dialogRef = this.dialogModel.open(AddUpdateFundHouseComponent, {
      disableClose: false,
      //panelClass: 'fullscreen-dialog',
      width: '50%'
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadFundHouses();
      }
    });

  }


  openEditDialog(fundHouse: FundHouseDto) {
    let dialogRef = this.dialogModel.open(AddUpdateFundHouseComponent, {
      disableClose: false,
      width: '50%',
      data: fundHouse
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadFundHouses();
      }
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.gridDatasource.filter = filterValue.trim().toLowerCase();

    if (this.gridDatasource.paginator) {
      this.gridDatasource.paginator.firstPage();
    }
  }

  private setLoading(isLoading: boolean) {
    this.loading = isLoading;
    if (isLoading) {
      this.gridDatasource = new MatTableDataSource([]);
    }
  }


  displayedColumns = [
    'position',
    'name',
    'weight',
    'symbol',
    'name',
    'weight',
    'symbol',
    'name',
    'weight',
    'symbol',
    'name',
    'weight',
    'symbol'
  ];
  dataSource = ELEMENT_DATA;
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

// const ELEMENT_DATA: Array<PeriodicElement> = [];
const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' }
];



