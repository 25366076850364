import { Role } from "@app/_enums/role";
import { ModelBase } from "./model-base";

export class UserBaseModel extends ModelBase {
  public email: string;
  public emailConfirmed: boolean;
  public fullName: string;
  public username: string;
  public isActive: boolean;
  public role: Role;
}
export class UserListItemBaseModel extends UserBaseModel {
  public activeStatus: string;
  public emailConfirmedStatus: string;
}
export class FundAdminListItemModel extends UserListItemBaseModel {
  public fundHouseName: string;
  public fundName: string;
  public fundId: number;
  public fundHouseId: number;
}

export class FundHouseAdminListItemModel extends UserListItemBaseModel {
  public fundHouseId: number;
  public fundHouseName: string
}