import { SchemeDateSettingDto } from "@app/_services/dto/scheme-date-setting.dto";
import { SchemeInvestorDto } from "@app/_services/dto/scheme-investor.dto";
import { defaultDataRow, SchemeInvestorDataTableDefinition } from "@app/feature_components/scheme-investor-data-table/scheme-investor-data-table-definitiontype";
import { TableDefinitionUtility } from "../table-definition-helper";

const getSchemeInvestorAddressHtml = (schemeInvestor: SchemeInvestorDto): string => {
    let address = '';
    address = address
        .concat(schemeInvestor.address ? schemeInvestor.address : '')
        .concat(schemeInvestor.address ? '<br />' : '')
        .concat(schemeInvestor.address1 ? schemeInvestor.address1 : '')
        .concat(schemeInvestor.address1 ? '<br />' : '')
        .concat(schemeInvestor.address2 ? schemeInvestor.address2 : '')
        .concat(schemeInvestor.address2 ? '<br />' : '')
        .concat(schemeInvestor.city ? schemeInvestor.city : '')
        .concat(schemeInvestor.city ? ', ' : '')
        .concat(schemeInvestor.state ? schemeInvestor.state : '')
        .concat(schemeInvestor.state ? '<br />' : '')
        .concat(schemeInvestor.pincode ? schemeInvestor.pincode : '');


    return address === '' ? '-' : address;
}

const getSchemeInvestorBankDetails = (schemeInvestor: SchemeInvestorDto): string => {
    let bankDetails = '';

    bankDetails = bankDetails
        .concat(schemeInvestor.bankName ? schemeInvestor.bankName : '')
        .concat(schemeInvestor.bankName ? '<br />' : '')
        .concat(schemeInvestor.accountNumber ? schemeInvestor.accountNumber : '')
        .concat(schemeInvestor.accountNumber ? '<br />' : '')
        .concat(schemeInvestor.bankAccountType ? schemeInvestor.bankAccountType : '');

    return bankDetails === '' ? '-' : bankDetails;
}

const getSchemeInvestorDematDetails = (schemeInvestor: SchemeInvestorDto): string => {
    let dematDetails = '';

    dematDetails = dematDetails
        .concat(schemeInvestor.depositorId ? 'CDSL/DP ID - '.concat(schemeInvestor.depositorId) : '')
        .concat(schemeInvestor.depositorId ? '<br />' : '')
        .concat(schemeInvestor.clientId ? 'Client ID - '.concat(schemeInvestor.clientId) : '');

    return dematDetails === '' ? '-' : dematDetails;
}

export const getInvestorDetailTableDefinition = (
    schemeInvestor: SchemeInvestorDto,
    currentUserEmail: string,
    schemeDateSetting: SchemeDateSettingDto,
    utility: TableDefinitionUtility
):
    SchemeInvestorDataTableDefinition => {
    return {
        headerRowDefinitions:
            [
                [
                    {
                        text: 'Folio # '.concat(schemeInvestor.folioNo).concat(': ').concat(schemeInvestor.investorName.toUpperCase()) +
                            (schemeDateSetting?.schemeInvestorDetailsNote ?
                                '&nbsp;<sup>'.concat(schemeDateSetting?.schemeInvestorDetailsNoteIndex?.toString()).concat('</sup>') :
                                ''),
                        colSpan: 4,
                        rowSpan: 1
                    }
                ]
            ],
        dataRows: [
            defaultDataRow(['PAN', utility.string(schemeInvestor.taxPanNumber), 'Tax Status', utility.string(schemeInvestor.taxStatus)]),
            defaultDataRow(['Address', getSchemeInvestorAddressHtml(schemeInvestor),
                'Bank Details', getSchemeInvestorBankDetails(schemeInvestor)]),
            defaultDataRow(['Mobile', utility.string(schemeInvestor.contactNo), 'Demat Details', getSchemeInvestorDematDetails(schemeInvestor)]),
            defaultDataRow(['Email', currentUserEmail, 'Nominee Details', utility.string(schemeInvestor.nomineeDetails)]),
        ],
        dataColumnDefinitions: [
            { widthPercent: 12 },
            { widthPercent: 40 },
            { widthPercent: 10 },
            { widthPercent: 40 }
        ]

    }
}