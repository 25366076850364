import { KeyValue } from "@angular/common";
import { Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CommonService } from "@app/_services/common.service";
import { InvestorDashboardDataDto } from "@app/_services/dto/investor-dashboard-data.dto";
import { SchemeInvestorDataOnDateDto } from "@app/_services/dto/scheme-investor-data-on-date.dto";
import { SchemeInvestorDto } from "@app/_services/dto/scheme-investor.dto";
import { LocalStorageService } from "@app/_services/local-storage.service";
import { SessionStorageService } from "@app/_services/session-storage.service";
import { AppUserBaseComponent } from "@app/app-user-base.component";
import { SchemeInvestorDataTableDefinition } from "./scheme-investor-data-table-definitiontype";

@Component({
    selector: 'app-scheme-investor-data-table',
    templateUrl: './scheme-investor-data-table.html',
    styleUrls: ['./scheme-investor-data-table.scss']
})
export class SchemeInvestorDataTableComponent extends AppUserBaseComponent {

    @Input() tableDefinition: SchemeInvestorDataTableDefinition;

    investorDashboardData: InvestorDashboardDataDto;
    selectedDate: KeyValue<string, Date>;
    selectedSchemeInvestor: SchemeInvestorDto;
    selectedSchemeInvestorDataDate: SchemeInvestorDataOnDateDto;
    constructor(
        localStorageService: LocalStorageService,
        activatedRoute: ActivatedRoute,
        commonService: CommonService,
        sessionStorageService: SessionStorageService,
    ) {
        super(activatedRoute, localStorageService, sessionStorageService, commonService);
    }
}