import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { InvestorListItemModel } from '@app/_models/investor';
import { AdminService } from '@app/_services/admin.service';
import { AlertsService } from '@app/_services/alerts.service';
import { CommonService } from '@app/_services/common.service';
import { FundHouseDto } from '@app/_services/dto/fund-house.dto';
import { AddUpdateInvestorDto } from '@app/_services/dto/investor.dto';
import { LocalStorageService } from '@app/_services/local-storage.service';
import { RegistrationService } from '@app/_services/registration.service';
import { SessionStorageService } from '@app/_services/session-storage.service';
import { AppUserBaseComponent } from '@app/app-user-base.component';
import { first } from 'rxjs';

@Component({
  selector: 'app-add-update-investor',
  templateUrl: 'add-update-investor.component.html',
  styleUrls: ['add-update-investor.component.scss']
})
export class AddUpdateInvestorComponent extends AppUserBaseComponent implements OnInit {

  form!: FormGroup;
  submitted = false;
  loading = false;
  fundHouses: FundHouseDto[] = [];
  selectedFundHouse: FundHouseDto = null;

  constructor(
    public dialogRef: MatDialogRef<AddUpdateInvestorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InvestorListItemModel,
    private fb: FormBuilder,
    private alertsService: AlertsService,
    private registrationService: RegistrationService,
    private adminService: AdminService,
    localStorageService: LocalStorageService,
    activatedRoute: ActivatedRoute,
    commonService: CommonService,
    sessionStorageService: SessionStorageService,
  ) {
    super(activatedRoute, localStorageService, sessionStorageService, commonService);

    dialogRef.disableClose = false;
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
  }

  ngOnInit() {
    this.form = this.fb.group({
      id: [null],
      fullName: ['', Validators.required],
      identityPanNumber: ['', Validators.required],
      identifier: ['', Validators.required],
      fundHouseId: ['', Validators.required],
      isActive: [true, Validators.required]
    }
    );

    this.fundHouses = this.localStorageService.fundHouses;

    if (this.isUpdate) {
      this.form.controls.id.setValue(this.data.id);
      this.form.controls.fullName.setValue(this.data.fullName);
      this.form.controls.fundHouseId.setValue(this.data.fundHouseId);
      this.form.controls.identityPanNumber.setValue(this.data.identityPanNumber);
      this.form.controls.identifier.setValue(this.data.identifier);
      this.form.controls.isActive.setValue(this.data.isActive);

      this.selectedFundHouse = this.localStorageService.fundHouses.find(x => x.id = this.data.fundHouseId);
    }
  }

  get registerFormControl() {
    return this.form.controls;
  }


  public get isUpdate(): boolean {
    return (this.data && this.data.id != null);
  }


  onSubmit() {
    if (this.form.valid) {
      this.addUpdateInvestor();
    }
    else {
      this.dialogRef.addPanelClass('invalid-modal');
    }
  }

  private addUpdateInvestor() {
    let investor = new AddUpdateInvestorDto();

    investor.id = this.registerFormControl.id.value;
    investor.fullName = this.registerFormControl.fullName.value;
    investor.identityPanNumber = this.registerFormControl.identityPanNumber.value;
    investor.identifier = this.registerFormControl.identifier.value;
    investor.fundHouseId = this.registerFormControl.fundHouseId.value;
    investor.isActive = this.registerFormControl.isActive.value;

    this.loading = true;
    this.registrationService.addUpdateInvestor(investor)
      .pipe(first())
      .subscribe({
        next: (response) => {
          this.submitted = false;
          this.loading = false;
          if (response.isSuccess) {
            this.alertsService.showInfo(`Investor ${this.isUpdate ? 'updated' : 'added'} successfully!.`, "Message", "");
            this.dialogRef.close(true);
          }
          else {
            this.alertsService.showError(response.message);
          }
        },
        error: error => {
          this.loading = false;
          error = error.message;
        }
      });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
