import { FundHouseDto } from "./fund-house.dto";
import { ShortNameFullNameEntitiesBaseDto } from "./shortNameFullNameBase.dto";

export class FundDto extends ShortNameFullNameEntitiesBaseDto {
  public sebiRegistrationNumber: string = '';
  public investmentManagerName: string = '';
  public sponsorName: string = '';
  public merchantBankerName: string = '';
  public legalAdvisorName: string = '';
  public fundHouseId: number = null;
  public fundHouse: FundHouseDto = null;
}