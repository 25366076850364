<h2 mat-dialog-title>{{isUpdate?'Update':'Add'}} Scheme</h2>

<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Fund House</mat-label>
            <input *ngIf="isUpdate" type="text" matInput [value]="selectedFundHouse?.combinedName" readonly="true">
            <mat-select *ngIf="!isUpdate" formControlName="fundHouseId" (selectionChange)="onFundHouseChange($event)">
                <mat-option *ngFor="let fh of fundHouses" [value]="fh.id">
                    {{fh.combinedName}}
                </mat-option>
            </mat-select>
            <mat-error
                *ngIf="(formControl.fundHouseId.touched || submitted) && formControl.fundHouseId.errors?.required">
                Fund house is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Fund</mat-label>
            <input *ngIf="isUpdate" type="text" matInput [value]="selectedFund?.combinedName" readonly="true">
            <mat-select *ngIf="!isUpdate" formControlName="fundId">
                <mat-option *ngFor="let f of funds" [value]="f.id">
                    {{f.combinedName}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="(formControl.fundId.touched || submitted) && formControl.fundId.errors?.required">
                Fund is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Short Name</mat-label>
            <input type="text" matInput formControlName="shortName" placeholder="Short Name" maxlength="15"
                [attr.cdkFocusInitial]="isUpdate">
            <mat-error *ngIf="(formControl.shortName.touched || submitted) && formControl.shortName.errors?.required">
                Short Name is required
            </mat-error>
            <mat-error *ngIf="(formControl.shortName.touched || submitted) && formControl.shortName.errors?.pattern">
                Short Name can only consists of alphabets, numbers and _
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Full Name</mat-label>
            <input type="text" matInput formControlName="fullName" placeholder="Full Name" maxlength="100">
            <mat-error *ngIf="(formControl.fullName.touched || submitted) && formControl.fullName.errors?.required">
                Full Name is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Scheme Manager</mat-label>
            <input type="text" matInput formControlName="schemeManager" placeholder="Scheme Manager" maxlength="250">
        </mat-form-field>
        <mat-form-field class="date-mat-field" appearance="outline">
            <mat-label>Launch Date</mat-label>
            <div class="date_container">
                <input type="text" readonly="true" matInput [matDatepicker]="pickerLaunchDate"
                    formControlName="launchDate">
                <mat-datepicker-toggle matIconSuffix [for]="pickerLaunchDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerLaunchDate></mat-datepicker>
            </div>
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Currency Format</mat-label>
            <div>
                <mat-select formControlName="currencyFormatCulture">
                    <mat-option *ngFor="let cf of currencyFormats" [value]="cf.key">
                        {{cf.value}}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="(formControl.currencyFormatCulture.touched || submitted) && formControl.currencyFormatCulture.errors?.required">
                    Currency format is required
                </mat-error>
            </div>
        </mat-form-field>
        <mat-form-field class="date-mat-field" appearance="outline">
            <mat-label>Date1</mat-label>
            <div class="date_container">
                <input type="text" readonly="true" matInput [matDatepicker]="pickerDate1" formControlName="date1">
                <mat-datepicker-toggle matIconSuffix [for]="pickerDate1"></mat-datepicker-toggle>
                <mat-datepicker #pickerDate1></mat-datepicker>
            </div>
        </mat-form-field>
        <mat-form-field class="date-mat-field" appearance="outline">
            <mat-label>Date2</mat-label>
            <div class="date_container">
                <input type="text" readonly="true" matInput [matDatepicker]="pickerDate2" formControlName="date2">
                <mat-datepicker-toggle matIconSuffix [for]="pickerDate2"></mat-datepicker-toggle>
                <mat-datepicker #pickerDate2></mat-datepicker>
            </div>
        </mat-form-field>
        <mat-form-field class="date-mat-field" appearance="outline">
            <mat-label>Date3</mat-label>
            <div class="date_container">
                <input type="text" readonly="true" matInput [matDatepicker]="pickerDate3" formControlName="date3">
                <mat-datepicker-toggle matIconSuffix [for]="pickerDate3"></mat-datepicker-toggle>
                <mat-datepicker #pickerDate3></mat-datepicker>
            </div>
        </mat-form-field>
        <mat-form-field class="date-mat-field" appearance="outline">
            <mat-label>Date4</mat-label>
            <div class="date_container">
                <input type="text" readonly="true" matInput [matDatepicker]="pickerDate4" formControlName="date4">
                <mat-datepicker-toggle matIconSuffix [for]="pickerDate4"></mat-datepicker-toggle>
                <mat-datepicker #pickerDate4></mat-datepicker>
            </div>
        </mat-form-field>
        <mat-form-field class="date-mat-field" appearance="outline">
            <mat-label>Date5</mat-label>
            <div class="date_container">
                <input type="text" readonly="true" matInput [matDatepicker]="pickerDate5" formControlName="date5">
                <mat-datepicker-toggle matIconSuffix [for]="pickerDate5"></mat-datepicker-toggle>
                <mat-datepicker #pickerDate5></mat-datepicker>
            </div>
        </mat-form-field>
        <mat-form-field class="date-mat-field" appearance="outline">
            <mat-label>Date6</mat-label>
            <div class="date_container">
                <input type="text" readonly="true" matInput [matDatepicker]="pickerDate6" formControlName="date6">
                <mat-datepicker-toggle matIconSuffix [for]="pickerDate6"></mat-datepicker-toggle>
                <mat-datepicker #pickerDate6></mat-datepicker>
            </div>
        </mat-form-field>
        <div class="scheme-investor-data-section">
            <mat-label>Scheme Investor Data</mat-label>
            <div>
                <div>
                    <mat-checkbox matInput formControlName="showCommitmentAndContributionSummary">Commitment And
                        Contribution
                        Summary</mat-checkbox>
                    <mat-checkbox matInput formControlName="showIncomeAndExpenseSummary">Income And Expense
                        Summary</mat-checkbox>
                    <mat-checkbox matInput formControlName="showPerformance">Performance</mat-checkbox>
                    <mat-checkbox matInput formControlName="showTaxationSummary">Taxation Summary</mat-checkbox>
                </div>
                <div>
                    <mat-checkbox matInput formControlName="showCapitalTransactions">Capital Transactions</mat-checkbox>
                    <mat-checkbox matInput formControlName="showIncomeDistribution">Income Distribution</mat-checkbox>
                    <mat-checkbox matInput formControlName="showTDSDistribution">TDS Distribution</mat-checkbox>
                    <mat-checkbox matInput formControlName="showOtherTransactions">Other Transactions</mat-checkbox>
                </div>
            </div>

        </div>


        <!-- <mat-form-field class="w-100" appearance="fill">
            <input type="text" matInput hidden="true">
            <mat-checkbox matInput formControlName="showCommitmentAndContributionSummary"> Show Commitment And
                Contribution Summary
            </mat-checkbox>
        </mat-form-field> -->
        <!-- <mat-form-field appearance="outline">
            <mat-label>Scheme Investor Data</mat-label>
            <input type="text" matInput hidden="true">
            <div>
                <div>
                    <mat-checkbox matInput formControlName="showCommitmentAndContributionSummary">Commitment And
                        Contribution
                        Summary</mat-checkbox>
                    <mat-checkbox matInput formControlName="showIncomeAndExpenseSummary">Income And Expense
                        Summary</mat-checkbox>
                    <mat-checkbox matInput formControlName="showPerformance">Performance</mat-checkbox>
                    <mat-checkbox matInput formControlName="showTaxationSummary">Taxation Summary</mat-checkbox>
                </div>
                <div>
                    <mat-checkbox matInput formControlName="showCapitalTransactions">Capital Transactions</mat-checkbox>
                    <mat-checkbox matInput formControlName="showIncomeDistribution">Income Distribution</mat-checkbox>
                    <mat-checkbox matInput formControlName="showTDSDistribution">TDS Distribution</mat-checkbox>
                    <mat-checkbox matInput formControlName="showOtherTransactions">Other Transactions</mat-checkbox>
                </div>
            </div>
        </mat-form-field> -->
        <!-- <mat-label>Scheme Investor Data</mat-label>
        <mat-selection-list>
            <mat-list-option checkboxPosition="before" ngDefaultControl mat-input
                formControlName="showCommitmentAndContributionSummary">
                Commitment And Contribution Summary
            </mat-list-option>
            <mat-list-option checkboxPosition="before" ngDefaultControl mat-input
                formControlName="showIncomeAndExpenseSummary">
                Income And Expense Summary
            </mat-list-option>
        </mat-selection-list> -->
    </mat-dialog-content>
    <mat-dialog-actions>
        <button [disabled]="loading" type="submit" mat-raised-button color="primary"
            class="me-3 w-40 rounded-pill btn-ht">
            <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
            {{isUpdate?'Update':'Submit'}}</button>
        <button type="button" mat-raised-button color="warn" class="me-3 w-40 rounded-pill btn-ht"
            (click)="onNoClick()">Close</button></mat-dialog-actions>
</form>