import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { currencyFormats } from '@app/_constants/currencyFormats';
import { SchemeListItemModel } from '@app/_models/scheme';
import { KpfsDatePipe } from '@app/_pipes/KpfsDateTimePipe';
import { CommonService } from '@app/_services/common.service';
import { DataDtoFilter, DataDtoRange } from '@app/_services/dto/response.dto';
import { FeaturePermissionService } from '@app/_services/feature-permission.service';
import { LocalStorageService } from '@app/_services/local-storage.service';
import { SessionStorageService } from '@app/_services/session-storage.service';
import { AppUserBaseComponent } from '@app/app-user-base.component';
import { AddUpdateSchemeComponent } from '../add-update-scheme';

@Component({
  selector: 'app-schemes',
  templateUrl: './schemes.component.html',
  styleUrls: ['./schemes.component.scss']
})
export class SchemesComponent extends AppUserBaseComponent implements OnInit, AfterViewInit {
  @ViewChild('filterInput') filterInput: ElementRef<HTMLElement>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('gridSort') gridSort = new MatSort();

  gridColumns: string[] = [
    'id',
    'shortName',
    'fullName',
    'fundFullName',
    'fundHouseFullName',
    'schemeManager',
    'launchDate',
    'currencyFormat',
    'date1',
    'date2',
    'date3',
    'date4',
    'date5',
    'date6',
    'actions'
  ];
  gridDatasource: MatTableDataSource<SchemeListItemModel>;
  public range: DataDtoRange = new DataDtoRange();
  public filter: DataDtoFilter = new DataDtoFilter();
  public loading: boolean = false;

  constructor(private dialogModel: MatDialog,
    commonService: CommonService,
    sessionStorageService: SessionStorageService,
    public readonly featurePermission: FeaturePermissionService,
    localStorageService: LocalStorageService,
    activatedRoute: ActivatedRoute,
    private readonly datePipe: KpfsDatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(activatedRoute, localStorageService, sessionStorageService, commonService);
    this.range.limit = 10;
  }

  public async ngOnInit(): Promise<void> {
    await this.loadSchemes();
  }

  ngAfterViewInit(): void {
    if (this.gridDatasource) {
      this.gridDatasource.paginator = this.paginator;
      this.gridDatasource.sort = this.gridSort;
    }
  }

  public async loadSchemes() {
    this.setLoading(true);
    this.commonService.getAllSchemes().then(response => {
      this.setLoading(false);
      if (response.isSuccess) {
        const schemeModels = response.data.map(scheme => {
          let schemeModel = new SchemeListItemModel();
          schemeModel.id = scheme.id;
          schemeModel.fundId = scheme.fundId;
          schemeModel.fundHouseId = scheme.fund.fundHouseId;
          schemeModel.shortName = scheme.shortName;
          schemeModel.fullName = scheme.fullName;
          schemeModel.fundHouseShortName = scheme.fund.fundHouse.shortName;
          schemeModel.fundHouseFullName = scheme.fund.fundHouse.fullName;
          schemeModel.fundShortName = scheme.fund.shortName;
          schemeModel.fundFullName = scheme.fund.fullName;
          schemeModel.schemeManager = scheme.schemeManager;
          schemeModel.launchDate = scheme.launchDate;
          schemeModel.currencyFormat = currencyFormats.find(x => x.key === scheme.currencyFormatCulture).value;
          schemeModel.date1 = scheme.date1;
          schemeModel.date2 = scheme.date2;
          schemeModel.date3 = scheme.date3;
          schemeModel.date4 = scheme.date4;
          schemeModel.date5 = scheme.date5;
          schemeModel.date6 = scheme.date6;
          schemeModel.showCommitmentAndContributionSummary = scheme.showCommitmentAndContributionSummary;
          schemeModel.showIncomeAndExpenseSummary = scheme.showIncomeAndExpenseSummary;
          schemeModel.showPerformance = scheme.showPerformance;
          schemeModel.showTaxationSummary = scheme.showTaxationSummary;
          schemeModel.showCapitalTransactions = scheme.showCapitalTransactions;
          schemeModel.showIncomeDistribution = scheme.showIncomeDistribution;
          schemeModel.showTDSDistribution = scheme.showTDSDistribution;
          schemeModel.showOtherTransactions = scheme.showOtherTransactions;

          return schemeModel;
        });

        this.gridDatasource = new MatTableDataSource(schemeModels);
        this.gridDatasource.paginator = this.paginator;
        this.gridDatasource.sort = this.gridSort;
        this.range.total = response.dataTotalCount;

        this.gridDatasource.filterPredicate = (data, filter: string) => {
          const accumulator = (currentTerm, key) => {
            if (key === 'launchDate') {
              return currentTerm + (this.datePipe.transform(data.launchDate));
            }
            else if (key === 'date1') {
              return currentTerm + (this.datePipe.transform(data.date1));
            }
            else if (key === 'date2') {
              return currentTerm + (this.datePipe.transform(data.date2));
            }
            else if (key === 'date3') {
              return currentTerm + (this.datePipe.transform(data.date3));
            }
            else if (key === 'date4') {
              return currentTerm + (this.datePipe.transform(data.date4));
            }
            else if (key === 'date5') {
              return currentTerm + (this.datePipe.transform(data.date5));
            }
            else if (key === 'date6') {
              return currentTerm + (this.datePipe.transform(data.date6));
            }

            return currentTerm + data[key];

          };
          const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
          const transformedFilter = filter.trim().toLowerCase();
          return dataStr.indexOf(transformedFilter) !== -1;
        };

        const event = new KeyboardEvent('keyup', { 'bubbles': true });
        this.filterInput.nativeElement.dispatchEvent(event);
      }
    });
  }

  public async onPage(event: PageEvent) {
    this.range.limit = event.pageSize;
    this.range.offset = event.pageIndex * event.pageSize;
    await this.loadSchemes();
  }

  openAddDialog() {
    let dialogRef = this.dialogModel.open(AddUpdateSchemeComponent, {
      disableClose: false,
      //panelClass: 'fullscreen-dialog',
      width: '50%',
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadSchemes();
      }
    });

  }


  openEditDialog(scheme: SchemeListItemModel) {
    let dialogRef = this.dialogModel.open(AddUpdateSchemeComponent, {
      disableClose: false,
      width: '50%',
      data: scheme
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadSchemes();
      }
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.gridDatasource.filter = filterValue.trim().toLowerCase();

    if (this.gridDatasource.paginator) {
      this.gridDatasource.paginator.firstPage();
    }
  }

  private setLoading(isLoading: boolean) {
    this.loading = isLoading;
    if (isLoading) {
      this.gridDatasource = new MatTableDataSource([]);
    }
  }
}
