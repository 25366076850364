export type SchemeInvestorDataTableDefinition =
    {
        headerRowDefinitions: HeaderRowDefinition[][],
        dataColumnDefinitions: DataColumnDefinition[],
        dataRows: DataRow[]
        footerRowDefinitions?: FooterRowDefinition[][],
        topNotes?: string[],
        bottomNotes?: string[]
    }

export type HeaderRowDefinition =
    {
        text: string,
        rowSpan: number,
        colSpan: number,
        css?: string
    }
export type FooterRowDefinition =
    {
        text: string,
        rowSpan: number,
        colSpan: number,
        css?: string
    }
export type DataColumnDefinition =
    {
        widthPercent: number,
        css?: string
    }

export type DataRow =
    {
        css?: string,
        data: string[]
    }

export const defaultColumnDefinition = (text: string): HeaderRowDefinition => {
    return { text: text, rowSpan: 1, colSpan: 1 };
}

export const defaultDataRow = (data: string[]): DataRow => {
    //return { css: '', data: data };
    return { data: data };
}