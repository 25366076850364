import { SchemeInvestorDataOnDateDto } from "@app/_services/dto/scheme-investor-data-on-date.dto";
import { SchemeInvestorDto } from "@app/_services/dto/scheme-investor.dto";
import { LocalStorageService } from "@app/_services/local-storage.service";
import { SchemeInvestorDataTableDefinition } from "@app/feature_components/scheme-investor-data-table/scheme-investor-data-table-definitiontype";
import { getCapitalTransactionsTableDefinition } from "./table-definitions/capital-transactions-table-definition";
import { getCommitmentContributionSummaryTableDefinition } from "./table-definitions/commitment-contribution-table-definition";
import { getIncomeDistributionTableDefinition } from "./table-definitions/income-distribution-table-definition";
import { getIncomeExpenseSummaryTableDefinition } from "./table-definitions/income-expense-summary-table-definition";
import { getInvestorDetailTableDefinition } from "./table-definitions/investor-detail-table-definition";
import { getOtherTransactionTableDefinition } from "./table-definitions/other-transaction-table-definition";
import { getPerformanceTableDefinition } from "./table-definitions/performance-table-definition";
import { getSchemeDetailTableDefinition } from "./table-definitions/scheme-detail-table-definition";
import { getTaxationSummaryTableDefinition } from "./table-definitions/taxation-summary-table-definition";
import { getTDSDistributionTableDefinition } from "./table-definitions/tds-distribution-table-definition";

export class SchemeInvestorTableDefinitionHelper {
    constructor(
        private schemeInvestor: SchemeInvestorDto,
        private schemeInvestorDataOnDate: SchemeInvestorDataOnDateDto,
        private localStorageService: LocalStorageService) {

    }

    public getSchemeInvestorTableDetinition(tableType: SchemeInvestorTableType): SchemeInvestorDataTableDefinition {

        if (!this.schemeInvestor || !this.schemeInvestorDataOnDate || !this.localStorageService) {
            return null;
        }

        const utility = new TableDefinitionUtility(this.schemeInvestor.currencyFormatCulture ?? this.schemeInvestor.scheme.currencyFormatCulture);

        switch (tableType) {
            case SchemeInvestorTableType.InvestorDetailTable:
                {
                    return getInvestorDetailTableDefinition
                        (
                            this.schemeInvestor,
                            this.localStorageService.currentUser.email,
                            this.schemeInvestorDataOnDate.schemeDateSetting,
                            utility
                        );
                }
            case SchemeInvestorTableType.SchemeDetailTable:
                {
                    return getSchemeDetailTableDefinition
                        (
                            this.schemeInvestor,
                            this.schemeInvestorDataOnDate.schemeDateSetting,
                            utility
                        );
                }
            case SchemeInvestorTableType.CommitmentAndContributionSummary:
                {
                    return getCommitmentContributionSummaryTableDefinition(
                        this.schemeInvestorDataOnDate?.siCommitmentContributions,
                        this.schemeInvestorDataOnDate.schemeDateSetting,
                        utility
                    );
                }
            case SchemeInvestorTableType.IncomeAndExpenseSummary:
                {
                    return getIncomeExpenseSummaryTableDefinition(
                        this.schemeInvestorDataOnDate,
                        this.schemeInvestorDataOnDate.schemeDateSetting,
                        utility);
                }
            case SchemeInvestorTableType.Performance:
                {
                    return getPerformanceTableDefinition
                        (
                            this.schemeInvestorDataOnDate?.siPerformances,
                            this.schemeInvestorDataOnDate.schemeDateSetting,
                            utility
                        );
                }
            case SchemeInvestorTableType.TaxationSummary:
                {
                    return getTaxationSummaryTableDefinition
                        (
                            this.schemeInvestorDataOnDate.siTaxationSummaries,
                            this.schemeInvestorDataOnDate.schemeDateSetting,
                            utility
                        );
                }
            case SchemeInvestorTableType.CapitalTransactions:
                {
                    return getCapitalTransactionsTableDefinition
                        (
                            this.schemeInvestorDataOnDate.siCapitalTransactions,
                            this.schemeInvestorDataOnDate.schemeDateSetting,
                            utility
                        );
                }
            case SchemeInvestorTableType.IncomeDistribution:
                {
                    return getIncomeDistributionTableDefinition
                        (
                            this.schemeInvestorDataOnDate.siIncomeDistributions,
                            this.schemeInvestorDataOnDate.schemeDateSetting,
                            utility
                        );
                }
            case SchemeInvestorTableType.TDSDistribution:
                {
                    return getTDSDistributionTableDefinition
                        (
                            this.schemeInvestorDataOnDate.sitdsDistributions,
                            this.schemeInvestorDataOnDate.siIncomeDistributions,
                            this.schemeInvestor,
                            this.schemeInvestorDataOnDate.schemeDateSetting,
                            utility
                        );
                }
            case SchemeInvestorTableType.OtherTransaction:
                {
                    return getOtherTransactionTableDefinition
                        (
                            this.schemeInvestorDataOnDate.siOtherTransactions,
                            this.schemeInvestorDataOnDate.schemeDateSetting,
                            utility
                        );
                }
            default:
                return null;
        }
    }
}

export enum SchemeInvestorTableType {
    InvestorDetailTable,
    SchemeDetailTable,
    CommitmentAndContributionSummary,
    IncomeAndExpenseSummary,
    Performance,
    TaxationSummary,
    CapitalTransactions,
    IncomeDistribution,
    TDSDistribution,
    OtherTransaction
}

export class TableDefinitionUtility {
    public constructor(private currencyFormatCulture: string) { }

    public string(s?: string, postString?: string, preString?: string): string {
        if (s) {
            return (preString ? preString : '').concat(s).concat(postString ? postString : '');
        }
        return '-';
    }

    public currency(decimalNumber?: number): string {
        if (decimalNumber) {
            return decimalNumber.toLocaleString(this.currencyFormatCulture);
        }
        return '-';
    }

    public number(decimalNumber?: number): string {
        if (decimalNumber) {
            return decimalNumber.toString();
        }
        return '-';
    }

    public rightAligned(s: string): string {
        return '<div style="text-align:right;width:100%">'.concat(s).concat('</div>');
    }

    public centerAligned(s: string): string {
        return '<div style="text-align:center;width:100%">'.concat(s).concat('</div>');
    }

    public rightAlignedCurrency(decimalNumber?: number): string {
        return this.rightAligned(this.currency(decimalNumber));
    }
}