import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutePaths } from '@app/_constants';
import { AuthenticationService } from '@app/_services';
import { AlertsService } from '@app/_services/alerts.service';
import { RegistrationService } from '@app/_services/registration.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-confirmation-email',
  templateUrl: 'confirmation-email.component.html',
  styleUrls: ['confirmation-email.component.scss']
})
export class ConfirmationEmailComponent implements OnInit {
  token: string;
  email: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertsService: AlertsService,
    private registrationService: RegistrationService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.authenticationService.logout();
    this.token = this.route.snapshot.paramMap.get('token');
    this.email = this.route.snapshot.paramMap.get('email');
    this.confirmEmail();
  }

  confirmEmail() {
    if (this.token && this.email) {
      this.registrationService.confirmEmail(this.email, this.token)
        .pipe(first())
        .subscribe({
          next: (response) => {
            if (response.isSuccess) {
              this.alertsService.showInfo("Congratulations! Your email has been confirmed successfully, please login to continue!");
              let userFundHouseName = response.data;
              if (userFundHouseName) {
                this.router.navigate([`${userFundHouseName}/${RoutePaths.login}`]);
              }
              else {
                this.router.navigate([RoutePaths.login]);
              }
            }
            else {
              this.alertsService.showError(response.message);
            }
          },
          error: error => {
            this.alertsService.showError(error);
          }
        });
    }
  }
}
