import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { toActiveInActiveString } from '@app/_helpers/helper';
import { InvestorListItemModel } from '@app/_models/investor';
import { AdminService } from '@app/_services/admin.service';
import { DataDtoFilter, DataDtoRange } from '@app/_services/dto/response.dto';
import { FeaturePermissionService } from '@app/_services/feature-permission.service';
import { AddUpdateInvestorComponent } from '../add-update-investor';

@Component({
  selector: 'app-investors',
  templateUrl: './investors.component.html',
  styleUrls: ['./investors.component.scss']
})
export class InvestorsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('empTbSort') empTbSort = new MatSort();
  @ViewChild('filterInput') filterInput: ElementRef<HTMLElement>;

  gridColumns: string[] = [
    'id',
    'fullName',
    'identityPanNumber',
    'identifier',
    'activeStatus',
    'fundHouseName',
    'actions'
  ];

  gridDatasource: MatTableDataSource<InvestorListItemModel>;
  discription: string[];
  selectedStatusName: string;
  public range: DataDtoRange = new DataDtoRange();
  public filter: DataDtoFilter = new DataDtoFilter();
  public loading: boolean = false;

  statusName: any = [{ id: 0, name: '' }];
  pdialogConfig: MatDialogConfig;

  constructor(private dialogModel: MatDialog,
    public readonly adminService: AdminService,
    public readonly featurePermissionService: FeaturePermissionService
  ) {

    this.range.limit = 10;
  }

  public async ngOnInit(): Promise<void> {
    this.loadInvestors();
  }

  ngAfterViewInit(): void {
    if (this.gridDatasource) {
      this.gridDatasource.paginator = this.paginator;
      this.gridDatasource.sort = this.empTbSort;
    }
  }

  public async loadInvestors() {
    this.setLoading(true);
    this.adminService.getAllInvestors().then(response => {
      this.setLoading(false);

      if (response.isSuccess) {

        const listItems = response.data.map(x => {
          return {
            id: x.id,
            fundHouseId: x.fundHouseId,
            fundHouseName: x.fundHouse.fullName,
            fullName: x.fullName,
            identityPanNumber: x.identityPanNumber,
            identifier: x.identifier,
            isActive: x.isActive,
            activeStatus: toActiveInActiveString(x.isActive),
          } as InvestorListItemModel;
        });

        this.gridDatasource = new MatTableDataSource(listItems);
        this.gridDatasource.paginator = this.paginator;
        this.gridDatasource.sort = this.empTbSort;
        this.range.total = response.dataTotalCount;
      }

      const event = new KeyboardEvent('keyup', { 'bubbles': true });
      this.filterInput.nativeElement.dispatchEvent(event);
    });
  }

  public async onPage(event: PageEvent) {
    this.range.limit = event.pageSize;
    this.range.offset = event.pageIndex * event.pageSize;
    await this.loadInvestors();
  }

  getstatusName(statusId: number) {
    const data = this.statusName.filter((c: { id: number; }) => c.id == statusId);
    if (data.length > 0) {
      return data[0].name;
    }
    return null;
  }

  addUserDialog() {
    let dialogRef = this.dialogModel.open(AddUpdateInvestorComponent, {
      disableClose: false,
      //panelClass: 'fullscreen-dialog',
      width: '50%'
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadInvestors();
      }
    });

  }

  openEditDialog(investor: InvestorListItemModel) {

    let dialogRef = this.dialogModel.open(AddUpdateInvestorComponent, {
      disableClose: false,
      width: '50%',

      data: investor
    });
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
    // When user close the dialog
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadInvestors();
      }
    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.gridDatasource.filter = filterValue.trim().toLowerCase();

    if (this.gridDatasource.paginator) {
      this.gridDatasource.paginator.firstPage();
    }
  }

  private setLoading(isLoading: boolean) {
    this.loading = isLoading;
    if (isLoading) {
      this.gridDatasource = new MatTableDataSource([]);
    }
  }
}
