<div class="dialog-custom-content-wrap dialog-custom-box">
  <div>
    <div class="mt-3">
      <div class="d-flex align-items-center justify-content-between bg-white py-2 px-3">
        <h2 class="dialog-custom-header-text m-0">Schemes</h2>
        <div class="d-flex align-items-center gap-3">
          <div class="user-grid-head">
            <mat-form-field appearance="outline">
              <mat-label>Filter</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Short Name" #filterInput>
            </mat-form-field>
          </div>

          <button mat-raised-button color="primary" class="me-3 w-100 rounded-pill btn-ht" (click)="openAddDialog()"
            *ngIf="featurePermission.canAddScheme">
            <mat-icon class="material-icons-outlined">money</mat-icon>
            Add Scheme</button>
        </div>
      </div>

      <mat-card-content>
        <div id="content-container" fxLayout="column">
          <table mat-table [dataSource]="gridDatasource" class="body-text" matSort #gridSort="matSort">
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Id </th>
              <td mat-cell *matCellDef="let item">
                {{item?.id}}
              </td>
            </ng-container>

            <ng-container matColumnDef="shortName">
              <th mat-header-cell *matHeaderCellDef class="column-5 fw-semibold" mat-sort-header> Short Name </th>
              <td mat-cell *matCellDef="let item">
                {{item?.shortName}}
              </td>
            </ng-container>

            <ng-container matColumnDef="fullName">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Full Name </th>
              <td mat-cell *matCellDef="let item">{{item?.fullName}}</td>
            </ng-container>

            <ng-container matColumnDef="fundFullName">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header>
                Fund</th>
              <td mat-cell *matCellDef="let item">{{item?.fundFullName}}</td>
            </ng-container>

            <ng-container matColumnDef="fundHouseFullName">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header>
                Fund House</th>
              <td mat-cell *matCellDef="let item">{{item?.fundHouseFullName}}</td>
            </ng-container>

            <ng-container matColumnDef="schemeManager">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Scheme Manager </th>
              <td mat-cell *matCellDef="let item">{{item?.schemeManager}}</td>
            </ng-container>

            <ng-container matColumnDef="launchDate">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Launch Date
              </th>
              <td mat-cell *matCellDef="let item">{{item?.launchDate| kpfsDate}}</td>
            </ng-container>

            <ng-container matColumnDef="currencyFormat">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Currency Format
              </th>
              <td mat-cell *matCellDef="let item">{{item?.currencyFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="date1">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Date1
              </th>
              <td mat-cell *matCellDef="let item">{{item?.date1 | kpfsDate}}</td>
            </ng-container>

            <ng-container matColumnDef="date2">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Date2
              </th>
              <td mat-cell *matCellDef="let item">{{item?.date2 | kpfsDate}}</td>
            </ng-container>

            <ng-container matColumnDef="date3">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Date3
              </th>
              <td mat-cell *matCellDef="let item">{{item?.date3 | kpfsDate}}</td>
            </ng-container>

            <ng-container matColumnDef="date4">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Date4
              </th>
              <td mat-cell *matCellDef="let item">{{item?.date4 | kpfsDate}}</td>
            </ng-container>

            <ng-container matColumnDef="date5">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Date5
              </th>
              <td mat-cell *matCellDef="let item">{{item?.date5 | kpfsDate}}</td>
            </ng-container>

            <ng-container matColumnDef="date6">
              <th mat-header-cell *matHeaderCellDef class="column-5  fw-semibold" mat-sort-header> Date6
              </th>
              <td mat-cell *matCellDef="let item">{{item?.date6 | kpfsDate}}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell [hidden]="!featurePermission.canEditFund" *matHeaderCellDef
                class="column-5  fw-semibold"> </th>
              <td mat-cell [hidden]="!featurePermission.canEditFund" *matCellDef="let item">
                <button mat-icon-button matTooltip="Click to Edit" class="iconbutton" color="primary">
                  <mat-icon aria-label="Edit" (click)="openEditDialog(item)">edit</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="gridColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: gridColumns;"></tr>
            <tr class="mat-row no-data-row" *matNoDataRow>
              <!-- <td *ngIf="loading" class="mat-cell-load" [attr.colspan]="gridColumns.length">
                <div class="spinner">
                  <mat-spinner></mat-spinner>
                </div>
              </td> -->
              <td *ngIf="!loading" style="text-align: center;" class="mat-cell" [attr.colspan]="gridColumns.length">
                No data matching the filter.
              </td>
            </tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="25"
          aria-label="Select page of schemes"></mat-paginator>
      </mat-card-content>
    </div>

  </div>
</div>