import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { currencyFormats } from '@app/_constants/currencyFormats';
import { RegExPatterns } from '@app/_constants/regexPatterns';
import { AdminService } from '@app/_services/admin.service';
import { AlertsService } from '@app/_services/alerts.service';
import { CommonService } from '@app/_services/common.service';
import { DropdownItemDto } from '@app/_services/dto/drop-down-item.dto';
import { FundHouseDto } from '@app/_services/dto/fund-house.dto';
import { FundDto } from '@app/_services/dto/fund.dto';
import { InvestorDto } from '@app/_services/dto/investor.dto';
import { MasterDataDto } from '@app/_services/dto/master-data.dto';
import { AddUpdateSchemeInvestorDto, SchemeInvestorDto } from '@app/_services/dto/scheme-investor.dto';
import { LocalStorageService } from '@app/_services/local-storage.service';
import { SessionStorageService } from '@app/_services/session-storage.service';
import { AppUserBaseComponent } from '@app/app-user-base.component';
import { first } from 'rxjs';

@Component({
  selector: 'app-add-update-scheme-investor',
  templateUrl: 'add-update-scheme-investor.component.html',
  styleUrls: ['add-update-scheme-investor.component.scss']
})
export class AddUpdateSchemeInvestorComponent extends AppUserBaseComponent implements OnInit {

  form!: FormGroup;
  submitted = false;
  loading = false;

  fundHouses: FundHouseDto[] = [];
  funds: FundDto[] = [];
  schemes: DropdownItemDto[] = [];
  investorItems: InvestorDto[] = [];

  modeOfHoldings: string[] = [];
  bankAccountTypes: string[] = [];
  taxOptions: string[] = [];
  kpfsRecordStatuses: string[] = [];
  sebiInvestorTypes: string[] = [];
  masterData: MasterDataDto = null;

  selectedFundHouse: FundHouseDto;
  selectedFund: FundDto;
  selectedScheme: DropdownItemDto;
  selectedInvestor: InvestorDto;

  public currencyFormats = currencyFormats;

  constructor(
    public dialogRef: MatDialogRef<AddUpdateSchemeInvestorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SchemeInvestorDto,
    private fb: FormBuilder,
    private alertsService: AlertsService,
    private adminService: AdminService,
    commonService: CommonService,
    sessionStorageService: SessionStorageService,
    localStorageService: LocalStorageService,
    activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute, localStorageService, sessionStorageService, commonService);

    dialogRef.disableClose = false;
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
  }

  ngOnInit() {

    this.form = this.fb.group({
      id: [null],
      investorId: [null, [Validators.required]],
      fundHouseId: [null, [Validators.required]],
      fundId: [null, [Validators.required]],
      schemeId: [null, [Validators.required]],
      email1: ['', [Validators.required, Validators.email]],
      email2: ['', [Validators.email]],
      email3: ['', [Validators.email]],
      email4: ['', [Validators.email]],
      email5: ['', [Validators.email]],
      folioNo: ['', Validators.required],
      isCarryClass: [false],
      class: [''],
      forfeitAndTransferDate: [null],
      salutation: [''],
      modeOfHolding: [null],
      taxPanNumber: [''],
      contactNo: [''],
      address: [''],
      address1: [''],
      address2: [''],
      city: [''],
      state: [''],
      pincode: [''],
      bankAccountType: [null],
      bankName: [''],
      ifscCode: [''],
      micrCode: [''],
      accountNumber: [''],
      taxStatus: [null],
      setupFees: [null],
      managementFees: [null],
      operatingExpenses: [''],
      faceValue: [null, [Validators.pattern(RegExPatterns.validDecimalNumber)]],
      classOfUnits: [null],
      distributor: [null],
      hurdleRate: [null, [Validators.pattern(RegExPatterns.validDecimalNumber)]],
      catchUp: [null, [Validators.pattern(RegExPatterns.validDecimalNumber)]],
      additionalReturns: [null, [Validators.pattern(RegExPatterns.validDecimalNumber)]],
      sebiRegistrationNumber: [null],
      sebiInvestorType1: [null],
      sebiInvestorType2: [null],
      sebiInvestorType3: [null],
      capitalCommitment: [null, [Validators.pattern(RegExPatterns.validDecimalNumber)]],
      capitalContribution: [null, [Validators.pattern(RegExPatterns.validDecimalNumber)]],
      depositorId: [null],
      depositorName: [null],
      clientId: [null],
      nomineeDetails: [null],
      currencyFormatCulture: [null],
      kpfsRecordStatus: [null],
      kpfsIncompleteRecordRemark: [null]
    }
    );

    this.fundHouses = this.localStorageService.fundHouses;

    if (this.isUpdate) {

      this.form.controls.id.setValue(this.data.id);

      this.form.controls.investorId.setValue(this.data.investorId);
      this.form.controls.fundHouseId.setValue(this.data.fundHouseId);
      this.form.controls.fundId.setValue(this.data.fundId);
      this.form.controls.schemeId.setValue(this.data.schemeId);

      this.form.controls.email1.setValue(this.data.email1);
      this.form.controls.email2.setValue(this.data.email2);
      this.form.controls.email3.setValue(this.data.email3);
      this.form.controls.email4.setValue(this.data.email4);
      this.form.controls.email5.setValue(this.data.email5);

      this.form.controls.folioNo.setValue(this.data.folioNo);
      this.form.controls.isCarryClass.setValue(this.data.isCarryClass);
      this.form.controls.class.setValue(this.data.class);
      this.form.controls.forfeitAndTransferDate.setValue(this.data.forfeitAndTransferDate);
      this.form.controls.salutation.setValue(this.data.salutation);
      this.form.controls.modeOfHolding.setValue(this.data.modeOfHolding);
      this.form.controls.taxPanNumber.setValue(this.data.taxPanNumber);
      this.form.controls.contactNo.setValue(this.data.contactNo);
      this.form.controls.address.setValue(this.data.address);
      this.form.controls.address1.setValue(this.data.address1);
      this.form.controls.address2.setValue(this.data.address2);
      this.form.controls.city.setValue(this.data.city);
      this.form.controls.state.setValue(this.data.state);
      this.form.controls.pincode.setValue(this.data.pincode);
      this.form.controls.bankAccountType.setValue(this.data.bankAccountType);
      this.form.controls.bankName.setValue(this.data.bankName);
      this.form.controls.ifscCode.setValue(this.data.ifscCode);
      this.form.controls.micrCode.setValue(this.data.micrCode);
      this.form.controls.accountNumber.setValue(this.data.accountNumber);
      this.form.controls.taxStatus.setValue(this.data.taxStatus);
      this.form.controls.setupFees.setValue(this.data.setupFees);
      this.form.controls.managementFees.setValue(this.data.managementFees);
      this.form.controls.operatingExpenses.setValue(this.data.operatingExpenses);
      this.form.controls.sebiInvestorType1.setValue(this.data.sebiInvestorType1);
      this.form.controls.sebiInvestorType2.setValue(this.data.sebiInvestorType2);
      this.form.controls.sebiInvestorType3.setValue(this.data.sebiInvestorType3);
      this.form.controls.capitalCommitment.setValue(this.data.capitalCommitment);
      this.form.controls.capitalContribution.setValue(this.data.capitalContribution);
      this.form.controls.kpfsRecordStatus.setValue(this.data.kpfsRecordStatus);
      this.form.controls.kpfsIncompleteRecordRemark.setValue(this.data.kpfsIncompleteRecordRemark);
      this.form.controls.sebiRegistrationNumber.setValue(this.data.sebiRegistrationNumber);
      this.form.controls.faceValue.setValue(this.data.faceValue);
      this.form.controls.classOfUnits.setValue(this.data.classOfUnits);
      this.form.controls.distributor.setValue(this.data.distributor);
      this.form.controls.hurdleRate.setValue(this.data.hurdleRate);
      this.form.controls.catchUp.setValue(this.data.catchUp);
      this.form.controls.additionalReturns.setValue(this.data.additionalReturns);
      this.form.controls.depositorId.setValue(this.data.depositorId);
      this.form.controls.depositorName.setValue(this.data.depositorName);
      this.form.controls.clientId.setValue(this.data.clientId);
      this.form.controls.nomineeDetails.setValue(this.data.nomineeDetails);
      this.form.controls.currencyFormatCulture.setValue(this.data.currencyFormatCulture);

      this.selectedFundHouse = this.localStorageService.fundHouses.find(x => x.id === this.data.fundHouseId);
      this.selectedFund = { id: this.data.fundId, combinedName: `${this.data.fundShortName} - ${this.data.fundFullName}` } as FundDto;
      this.selectedScheme = { text: `${this.data.schemeShortName} - ${this.data.schemeFullName}`, value: this.data.schemeId } as DropdownItemDto;
      this.selectedInvestor = this.data.investor;
    }

    this.modeOfHoldings = this.localStorageService.masterData.modeOfHoldings.map(x => x.value);
    this.bankAccountTypes = this.localStorageService.masterData.bankAccountTypes.map(x => x.value);
    this.taxOptions = this.localStorageService.masterData.taxOptions.map(x => x.value);
    this.kpfsRecordStatuses = this.localStorageService.masterData.kpfsRecordStatuses.map(x => x.value);
    this.sebiInvestorTypes = this.localStorageService.masterData.sebiInvestorTypes.map(x => x.value);
  }

  get formControls() {
    return this.form.controls;
  }


  public get isUpdate(): boolean {
    return (this.data && this.data.id != null);
  }

  onSubmit() {
    this.dialogRef.removePanelClass('invalid-modal');
    if (this.form.valid) {
      if (this.isUpdate) {
        this.updateInvestor();
      }
      else {
        this.addInvestor();
      }
    }
    else {
      this.dialogRef.addPanelClass('invalid-modal');
    }
  }

  private addInvestor() {
    this.loading = true;
    this.adminService.addSchemeInvestor(this.getAddUpdateSchemeInvestorObject())
      .pipe(first())
      .subscribe({
        next: (response) => {
          this.submitted = false;
          this.loading = false;
          if (response.isSuccess) {
            this.alertsService.showInfo("Scheme Investor added successfully.", "Message", "");
            this.dialogRef.close(true);
          }
          else {
            this.alertsService.showError(response.message);
          }
        },
        error: error => {
          this.loading = false;
          error = error.message;
        }
      });
  }

  private updateInvestor() {
    this.loading = true;
    this.adminService.updateSchemeInvestor(this.getAddUpdateSchemeInvestorObject())
      .pipe(first())
      .subscribe({
        next: (response) => {
          this.submitted = false;
          this.loading = false;
          if (response.isSuccess) {
            this.alertsService.showInfo("Scheme Investor updated successfully.", "Message", "");
            this.dialogRef.close(true);
          }
          else {
            this.alertsService.showError(response.message);
          }
        },
        error: error => {
          this.loading = false;
          error = error.message;
        }
      });
  }

  private getAddUpdateSchemeInvestorObject(): AddUpdateSchemeInvestorDto {
    let schemeInvestor = new AddUpdateSchemeInvestorDto();

    schemeInvestor.id = this.formControls.id.value;
    schemeInvestor.investorId = this.formControls.investorId.value;
    schemeInvestor.schemeId = this.formControls.schemeId.value;
    schemeInvestor.fundHouseId = this.formControls.fundHouseId.value;

    schemeInvestor.email1 = this.formControls.email1.value;
    schemeInvestor.email2 = this.formControls.email2.value;
    schemeInvestor.email3 = this.formControls.email3.value;
    schemeInvestor.email4 = this.formControls.email4.value;
    schemeInvestor.email5 = this.formControls.email5.value;

    schemeInvestor.folioNo = this.formControls.folioNo.value;
    schemeInvestor.isCarryClass = this.formControls.isCarryClass.value;
    schemeInvestor.class = this.formControls.class.value;
    schemeInvestor.forfeitAndTransferDate = this.formControls.forfeitAndTransferDate.value;
    schemeInvestor.salutation = this.formControls.salutation.value;
    schemeInvestor.modeOfHolding = this.formControls.modeOfHolding.value;
    schemeInvestor.taxPanNumber = this.formControls.taxPanNumber.value;
    schemeInvestor.contactNo = this.formControls.contactNo.value;
    schemeInvestor.address = this.formControls.address.value;
    schemeInvestor.address1 = this.formControls.address1.value;
    schemeInvestor.address2 = this.formControls.address2.value;
    schemeInvestor.city = this.formControls.city.value;
    schemeInvestor.state = this.formControls.state.value;
    schemeInvestor.pincode = this.formControls.pincode.value;
    schemeInvestor.bankAccountType = this.formControls.bankAccountType.value;
    schemeInvestor.bankName = this.formControls.bankName.value;
    schemeInvestor.ifscCode = this.formControls.ifscCode.value;
    schemeInvestor.micrCode = this.formControls.micrCode.value;
    schemeInvestor.accountNumber = this.formControls.accountNumber.value;
    schemeInvestor.taxStatus = this.formControls.taxStatus.value;
    schemeInvestor.setupFees = this.formControls.setupFees.value;
    schemeInvestor.managementFees = this.formControls.managementFees.value;
    schemeInvestor.operatingExpenses = this.formControls.operatingExpenses.value;
    schemeInvestor.sebiInvestorType1 = this.formControls.sebiInvestorType1.value;
    schemeInvestor.sebiInvestorType2 = this.formControls.sebiInvestorType2.value;
    schemeInvestor.sebiInvestorType3 = this.formControls.sebiInvestorType3.value;
    schemeInvestor.capitalCommitment = this.formControls.capitalCommitment.value;
    schemeInvestor.capitalContribution = this.formControls.capitalContribution.value;
    schemeInvestor.kpfsRecordStatus = this.formControls.kpfsRecordStatus.value;
    schemeInvestor.kpfsIncompleteRecordRemark = this.formControls.kpfsIncompleteRecordRemark.value;
    schemeInvestor.sebiRegistrationNumber = this.formControls.sebiRegistrationNumber.value;
    schemeInvestor.faceValue = this.formControls.faceValue.value;
    schemeInvestor.classOfUnits = this.formControls.classOfUnits.value;
    schemeInvestor.distributor = this.formControls.distributor.value;
    schemeInvestor.hurdleRate = this.formControls.hurdleRate.value;
    schemeInvestor.catchUp = this.formControls.catchUp.value;
    schemeInvestor.additionalReturns = this.formControls.additionalReturns.value;
    schemeInvestor.depositorId = this.formControls.depositorId.value;
    schemeInvestor.depositorName = this.formControls.depositorName.value;
    schemeInvestor.clientId = this.formControls.clientId.value;
    schemeInvestor.nomineeDetails = this.formControls.nomineeDetails.value;
    schemeInvestor.currencyFormatCulture = this.formControls.currencyFormatCulture.value;

    return schemeInvestor;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  public onFundHouseChange(e: any) {
    this.investorItems = [];
    this.funds = [];
    this.schemes = [];

    this.formControls.fundId.setValue(null);
    this.formControls.fundId.markAsUntouched();

    this.formControls.schemeId.setValue(null);
    this.formControls.schemeId.markAsUntouched();

    this.formControls.investorId.setValue(null);
    this.formControls.investorId.markAsUntouched();

    this.commonService.getAllFunds(e.value).then(response => {
      if (response.isSuccess) {
        this.funds = response.data;
      }
    });

    this.adminService.getInvestorDropdownItems(e.value).then(response => {
      if (response.isSuccess) {
        this.investorItems = response.data;
      }
    });
  }

  public onFundChange(e: any) {

    this.formControls.schemeId.setValue(null);
    this.formControls.schemeId.markAsUntouched();

    this.adminService.getSchemeDropdownItems(this.formControls.fundHouseId.value, e.value).then(response => {
      if (response.isSuccess) {
        this.schemes = response.data;
      }
    });
  }
}
