import { SchemeDateSettingDto } from "@app/_services/dto/scheme-date-setting.dto";
import { SIPerformanceDto } from "@app/_services/dto/si-performance.dto";
import { DataRow, SchemeInvestorDataTableDefinition } from "@app/feature_components/scheme-investor-data-table/scheme-investor-data-table-definitiontype";
import { TableDefinitionUtility } from "../table-definition-helper";

export const getPerformanceTableDefinition =
    (
        siPerformances: SIPerformanceDto[],
        schemeDateSetting: SchemeDateSettingDto,
        utility: TableDefinitionUtility
    ):
        SchemeInvestorDataTableDefinition => {

        const dataRows = siPerformances?.map(x => {
            return {
                data: [
                    x.title.concat(x.note ? '&nbsp;<sup>'.concat(x.noteIndex?.toString()).concat('</sup>') : ''),
                    x.legend,
                    utility.currency(x.amount)
                ]
            } as DataRow
        });

        return {
            headerRowDefinitions:
                [
                    [
                        {
                            text: 'PERFORMANCE' +
                                (schemeDateSetting?.performanceNote ?
                                    '&nbsp;<sup>'.concat(schemeDateSetting?.performanceNoteIndex?.toString()).concat('</sup>') :
                                    ''),
                            colSpan: 2,
                            rowSpan: 1
                        },
                        {
                            text: 'AMOUNT (INR)',
                            colSpan: 1,
                            rowSpan: 1,
                            css: 'text-align:right'
                        }
                    ]
                ],
            dataRows: [
                ...dataRows
            ],
            dataColumnDefinitions: [
                { widthPercent: 30 },
                { widthPercent: 40, css: 'text-align:center' },
                { widthPercent: 30, css: 'text-align:right' }
            ]

        }
    }