import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FormDataKeys } from '@app/_constants/form-data-keys';
import { ImportDataType } from '@app/_enums/import-data-type';
import { AdminService } from '@app/_services/admin.service';
import { AlertsService } from '@app/_services/alerts.service';
import { CommonService } from '@app/_services/common.service';
import { LocalStorageService } from '@app/_services/local-storage.service';
import { SessionStorageService } from '@app/_services/session-storage.service';
import { AppUserBaseComponent } from '@app/app-user-base.component';

@Component({
  selector: 'app-import-data',
  templateUrl: 'import-data.component.html',
  styleUrls: ['import-data.component.scss']
})
export class ImportDataComponent extends AppUserBaseComponent implements OnInit {

  form!: FormGroup;
  submitted = false;
  loading = false;
  error = '';

  constructor(
    public dialogRef: MatDialogRef<ImportDataComponent>,
    @Inject(MAT_DIALOG_DATA) public importDataType: ImportDataType,
    private fb: FormBuilder,
    private alertsService: AlertsService,
    private adminService: AdminService,
    localStorageService: LocalStorageService,
    activatedRoute: ActivatedRoute,
    commonService: CommonService,
    sessionStorageService: SessionStorageService
  ) {

    super(activatedRoute, localStorageService, sessionStorageService, commonService);

    dialogRef.disableClose = false;
    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
  }

  async ngOnInit() {
    this.form = this.fb.group({
      fileName: ['', Validators.required]
    }
    );
  }

  get formControl() {
    return this.form.controls;
  }

  async onSubmit() {
    this.submitted = true;
    if (this.form.valid) {

      let formData = new FormData();
      formData.append(FormDataKeys.FILE, this.selectedFile);
      formData.append(FormDataKeys.DATA_TYPE, this.importDataType.toString());

      this.loading = true;
      (await this.adminService.importData(formData))
        .subscribe((response: Blob) => {
          this.loading = false;
          if (response.size === 0) {
            this.alertsService.showInfo(this.getImportSuccessMessage(), "Message", "");
            this.dialogRef.close(true);
          }
          else {
            if (response.type === 'text/plain') {
              response.text().then(error => {
                this.alertsService.showError(error);

                this.formControl.fileName.setValue('');
                this.formControl.fileName.markAsUntouched();
                this.submitted = false;
              });
            } else {
              this.alertsService.showError(`Errors in records! Error file has been downloaded. Please correct the errors and re-upload.`);
              const blob = new Blob([response]);
              const downloadLink = document.createElement('a');
              downloadLink.href = URL.createObjectURL(blob);
              downloadLink.download = "Error.xlsx";
              downloadLink.click();
              URL.revokeObjectURL(downloadLink.href);

              this.dialogRef.close(true);
            }
          }
        });
    }
  }

  get dialogTitle(): string {
    switch (this.importDataType) {
      case ImportDataType.SchemeInvestorData:
        return 'Scheme Investor Data';
      case ImportDataType.InvestorDocumentLink:
        return 'Link Invetor Document';
      default:
        return '';
    }
  }

  private getImportSuccessMessage(): string {
    switch (this.importDataType) {
      case ImportDataType.SchemeInvestorData:
        return 'Schemes and Investors data imported successfully!.';
      case ImportDataType.InvestorDocumentLink:
        return 'Investors are linked to documents successfully!.';
      default:
        return '';
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  selectedFile: File = null;

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0] ?? null;
    this.formControl.fileName.setValue(this.selectedFile.name);

    (event.target as HTMLInputElement).value = '';
  }
}
