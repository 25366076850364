import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/_services';
import { UserDto } from '@app/_services/dto/user.dto';

@Component({
  selector: 'app-admin',
  templateUrl: 'admin.component.html',
  styleUrls: ['admin.component.scss']
})
export class AdminComponent implements OnInit {
  loading = false;
  users: UserDto[] = [];

  constructor(private userService: UserService) { }

  ngOnInit() {

  }
}