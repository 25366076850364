<div class="dialog-custom-content-wrap dialog-custom-box">
    <div>
        <div class="mt-3">
            <div class="d-flex align-items-center justify-content-between bg-white py-2 px-3 main-div">
                <h2 class="dialog-custom-header-text m-0">Dashboard</h2>
                <div class="d-flex align-items-center gap-3">
                    <div class="user-grid-head">

                    </div>
                </div>
            </div>
            <mat-card>
                <mat-card-content>
                    <div fxLayout="column">
                        <mat-tab-group dynamicHeight="" (selectedTabChange)="onTabChanged($event);">
                            <mat-tab *ngFor="let schemeInvestor of investorDashboardData?.schemeInvestors"
                                label='{{schemeInvestor.schemeShortName}}'>

                                <mat-card>
                                    <mat-card-content>
                                        <mat-form-field class="w-25" appearance="outline">
                                            <mat-label>As On Date</mat-label>
                                            <mat-select [(ngModel)]="selectedDatePair"
                                                (selectionChange)="onDateSelectionChange($event)">
                                                <mat-option *ngFor="let d of schemeInvestor.dates" [value]="d">
                                                    {{d.value | kpfsDate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <button mat-raised-button color="primary" class="me-3 w-10 rounded-pill btn-ht"
                                            style="float: right;" (click)="print()">
                                            <mat-icon class="material-icons-outlined">print</mat-icon>
                                            Print</button>
                                        <div id="content-container-60">
                                            <ng-template [ngIf]="loading" [ngIfElse]="dateData">
                                                <!-- <mat-spinner></mat-spinner> -->
                                            </ng-template>

                                            <ng-template #dateData>
                                                <ng-template [ngIf]="selectedDatePair">
                                                    <div id="scheme-investor-data">
                                                        <app-scheme-investor-data-table
                                                            [tableDefinition]="tableDefinitionHelper.getSchemeInvestorTableDetinition(schemeInvestorTableType.InvestorDetailTable)"></app-scheme-investor-data-table>
                                                        <app-scheme-investor-data-table
                                                            [tableDefinition]="tableDefinitionHelper.getSchemeInvestorTableDetinition(schemeInvestorTableType.SchemeDetailTable)"></app-scheme-investor-data-table>
                                                        <app-scheme-investor-data-table
                                                            *ngIf="canShowDataTable(schemeInvestorTableType.CommitmentAndContributionSummary)"
                                                            [tableDefinition]="tableDefinitionHelper.getSchemeInvestorTableDetinition(schemeInvestorTableType.CommitmentAndContributionSummary)"></app-scheme-investor-data-table>
                                                        <app-scheme-investor-data-table
                                                            *ngIf="canShowDataTable(schemeInvestorTableType.IncomeAndExpenseSummary)"
                                                            [tableDefinition]="tableDefinitionHelper.getSchemeInvestorTableDetinition(schemeInvestorTableType.IncomeAndExpenseSummary)"></app-scheme-investor-data-table>
                                                        <app-scheme-investor-data-table
                                                            *ngIf="canShowDataTable(schemeInvestorTableType.Performance)"
                                                            [tableDefinition]="tableDefinitionHelper.getSchemeInvestorTableDetinition(schemeInvestorTableType.Performance)"></app-scheme-investor-data-table>
                                                        <app-scheme-investor-data-table
                                                            *ngIf="canShowDataTable(schemeInvestorTableType.TaxationSummary)"
                                                            [tableDefinition]="tableDefinitionHelper.getSchemeInvestorTableDetinition(schemeInvestorTableType.TaxationSummary)"></app-scheme-investor-data-table>
                                                        <app-scheme-investor-data-table
                                                            *ngIf="canShowDataTable(schemeInvestorTableType.CapitalTransactions)"
                                                            [tableDefinition]="tableDefinitionHelper.getSchemeInvestorTableDetinition(schemeInvestorTableType.CapitalTransactions)"></app-scheme-investor-data-table>
                                                        <app-scheme-investor-data-table
                                                            *ngIf="canShowDataTable(schemeInvestorTableType.IncomeDistribution)"
                                                            [tableDefinition]="tableDefinitionHelper.getSchemeInvestorTableDetinition(schemeInvestorTableType.IncomeDistribution)"></app-scheme-investor-data-table>
                                                        <app-scheme-investor-data-table
                                                            *ngIf="canShowDataTable(schemeInvestorTableType.TDSDistribution)"
                                                            [tableDefinition]="tableDefinitionHelper.getSchemeInvestorTableDetinition(schemeInvestorTableType.TDSDistribution)"></app-scheme-investor-data-table>
                                                        <app-scheme-investor-data-table
                                                            *ngIf="canShowDataTable(schemeInvestorTableType.OtherTransaction)"
                                                            [tableDefinition]="tableDefinitionHelper.getSchemeInvestorTableDetinition(schemeInvestorTableType.OtherTransaction)"></app-scheme-investor-data-table>
                                                        <div class="notes"
                                                            *ngIf="selectedSchemeInvestorDataOnDate.notes?.length>0">
                                                            <div class="notes-header">
                                                                Notes</div>
                                                            <p
                                                                *ngIf="selectedSchemeInvestorDataOnDate.schemeDateSetting?.schemeNote">
                                                                {{selectedSchemeInvestorDataOnDate.schemeDateSetting?.schemeNote}}
                                                            </p>
                                                            <p
                                                                *ngFor="let note of selectedSchemeInvestorDataOnDate.notes">
                                                                <sup>{{note.index}}</sup>&nbsp;&nbsp;{{note.note}}
                                                            </p>
                                                        </div>
                                                        <div class="notice">
                                                            Please note that this account statement is a record of your
                                                            transaction in the {{schemeInvestor.schemeFullName}}. You
                                                            are requested to review this carefully and contact at the
                                                            above-mentioned email address in case of any discrepancy.
                                                            The contents of this statement will be considered to be
                                                            correct if no error is reported within 30 days from the day
                                                            of issue of this statement.
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </ng-template>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>