import { KpfsDatePipe } from "@app/_pipes/KpfsDateTimePipe";
import { SchemeDateSettingDto } from "@app/_services/dto/scheme-date-setting.dto";
import { SIIncomeDistributionDto } from "@app/_services/dto/si-income-distribution.dto";
import { DataRow, SchemeInvestorDataTableDefinition } from "@app/feature_components/scheme-investor-data-table/scheme-investor-data-table-definitiontype";
import { TableDefinitionUtility } from "../table-definition-helper";

export const getIncomeDistributionTableDefinition =
    (
        siIncomeDistributions: SIIncomeDistributionDto[],
        schemeDateSetting: SchemeDateSettingDto,
        utility: TableDefinitionUtility
    ): SchemeInvestorDataTableDefinition => {

        const amountTotal = siIncomeDistributions?.map(item => item?.amount).reduce((prev, next) => prev + next, 0);
        const kpfsDatePipe = new KpfsDatePipe();

        const dataRows = siIncomeDistributions?.map(x => {
            return {
                data: [
                    x.title.concat(x.note ? '&nbsp;<sup>'.concat(x.noteIndex?.toString()).concat('</sup>') : ''),
                    x.period,
                    kpfsDatePipe.transform(x.date),
                    utility.currency(x.amount)
                ]
            } as DataRow
        });

        return {
            headerRowDefinitions:
                [
                    [
                        {
                            text: 'INCOME DISTRIBUTION' + (schemeDateSetting?.incomeDistributionNote ?
                                '&nbsp;<sup>'.concat(schemeDateSetting?.incomeDistributionNoteIndex?.toString()).concat('</sup>') :
                                ''),
                            colSpan: 1,
                            rowSpan: 1
                        },
                        {
                            text: 'PERIOD',
                            colSpan: 1,
                            rowSpan: 1
                        },
                        {
                            text: 'DATE',
                            colSpan: 1,
                            rowSpan: 1,
                            css: 'text-align:right'
                        },
                        {
                            text: 'AMOUNT (INR)',
                            colSpan: 1,
                            rowSpan: 1,
                            css: 'text-align:right'
                        }
                    ]
                ],
            dataRows: [
                ...dataRows
            ],
            dataColumnDefinitions: [
                { widthPercent: 46 },
                { widthPercent: 18, css: 'white-space:nowrap' },
                { widthPercent: 18, css: 'text-align:right' },
                { widthPercent: 18, css: 'text-align:right' }
            ],
            footerRowDefinitions: [[
                {
                    text: 'Total Income Distribution (R)',
                    colSpan: 3,
                    rowSpan: 1
                },
                {
                    text: utility.currency(amountTotal),
                    colSpan: 1,
                    rowSpan: 1
                }
            ]]
        }
    }