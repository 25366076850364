import { Component } from '@angular/core';

import { SessionStorageKeys } from './_constants/storage-keys';
import { CommonService } from './_services/common.service';
import { SessionStorageService } from './_services/session-storage.service';
@Component({
    selector: 'app-base',
    template: `No template`
})
export class AppBaseComponent {
    constructor(
        public commonService: CommonService,
        public readonly sessionStorageService: SessionStorageService
    ) {
        if (!sessionStorageService.buildNumber) {
            this.commonService.getBuildNumber().then(response => {
                if (response.isSuccess) {
                    sessionStorage.setItem(SessionStorageKeys.BUILD_NUMBER, response.data);
                }
            });
        }
    }
}