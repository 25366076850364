export enum ImportDataType {
    CommitmentAndContributionSummary = 1,
    IncomeAndExpenseSummary = 2,
    Performance = 3,
    TaxationSummary = 4,
    CapitalTransactions = 5,
    IncomeDistribution = 6,
    TDSDistribution = 7,
    OtherTransactions = 8,
    SchemeNotes = 9,
    InvestorDocumentLink = 10,
    SchemeInvestorData = 11
}