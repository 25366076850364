import { DtoBase } from "./base.dto";
import { FundDto } from "./fund.dto";
import { ShortNameFullNameEntitiesBaseDto } from "./shortNameFullNameBase.dto";

export class SchemeDto extends ShortNameFullNameEntitiesBaseDto {
  public schemeManager: string = '';
  public launchDate: Date;
  public date1: Date;
  public date2: Date;
  public date3: Date;
  public date4: Date;
  public date5: Date;
  public date6: Date;
  public showCommitmentAndContributionSummary: boolean;
  public showIncomeAndExpenseSummary: boolean;
  public showPerformance: boolean;
  public showTaxationSummary: boolean;
  public showCapitalTransactions: boolean;
  public showIncomeDistribution: boolean;
  public showTDSDistribution: boolean;
  public showOtherTransactions: boolean;
  public currencyFormatCulture: string;

  public fundId: number = null;
  public fund: FundDto = null;
}

export class AddUpdateSchemeDto extends DtoBase<number> {
  public shortName: string = '';
  public fullName: string = '';
  public schemeManager: string = '';
  public launchDate?: Date = null;
  public currencyFormatCulture: string = '';
  public date1?: Date = null;
  public date2?: Date = null;
  public date3?: Date = null;
  public date4?: Date = null;
  public date5?: Date = null;
  public date6?: Date = null;
  public fundId: number = null;
  public showCommitmentAndContributionSummary: boolean = false;
  public showIncomeAndExpenseSummary: boolean = false;
  public showPerformance: boolean = false;
  public showTaxationSummary: boolean = false;
  public showCapitalTransactions: boolean = false;
  public showIncomeDistribution: boolean = false;
  public showTDSDistribution: boolean = false;
  public showOtherTransactions: boolean = false;
}