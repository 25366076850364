import { SchemeDateSettingDto } from "@app/_services/dto/scheme-date-setting.dto"
import { SchemeInvestorDto } from "@app/_services/dto/scheme-investor.dto"
import { defaultDataRow, SchemeInvestorDataTableDefinition } from "@app/feature_components/scheme-investor-data-table/scheme-investor-data-table-definitiontype"
import { TableDefinitionUtility } from "../table-definition-helper"

export const getSchemeDetailTableDefinition = (
    schemeInvestor: SchemeInvestorDto,
    schemeDateSetting: SchemeDateSettingDto,
    utility: TableDefinitionUtility
): SchemeInvestorDataTableDefinition => {
    return {
        headerRowDefinitions:
            [
                [
                    {
                        text: 'FUND/SCHEME DETAILS AND INVESTMENT TERMS' +
                            (schemeDateSetting?.schemeDetailsAndInvestmentTermsNote ?
                                '&nbsp;<sup>'.concat(schemeDateSetting?.schemeDetailsAndInvestmentTermsNoteIndex?.toString()).concat('</sup>') :
                                ''),
                        colSpan: 4,
                        rowSpan: 1,
                    }
                ]
            ],
        dataColumnDefinitions: [
            { widthPercent: 20 },
            { widthPercent: 20 },
            { widthPercent: 20 },
            { widthPercent: 20 }
        ],
        dataRows: [
            defaultDataRow(['Fund Name', schemeInvestor.fundFullName, 'Setup Fees', utility.string(schemeInvestor.setupFees, '% (one-time)')]),
            defaultDataRow(['SEBI Registration No.', utility.string(schemeInvestor.sebiRegistrationNumber), 'Management Fees', utility.string(schemeInvestor.managementFees, '% p.a')]),
            defaultDataRow(['Scheme Name', schemeInvestor.schemeFullName, 'Operating Expenses', utility.string(schemeInvestor.operatingExpenses, '% p.a. or actual (whichever is lower)')]),
            defaultDataRow(['Face Value', utility.string(schemeInvestor.faceValue?.toString(), ' per Unit', 'INR'), 'Hurdle Rate', utility.string(schemeInvestor.hurdleRate?.toString(), '% p.a.')]),
            defaultDataRow(['Class of Units', utility.string(schemeInvestor.classOfUnits), 'Catch-up', utility.string(schemeInvestor.catchUp?.toString(), '%')]),
            defaultDataRow(['Distributor', utility.string(schemeInvestor.distributor), 'Additional Returns', utility.string(schemeInvestor.additionalReturns?.toString(), '%')]),
        ]
    }
}