import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { ApiService } from './api.service';
import { IResponse } from './dto/response.dto';
import { UserDto } from './dto/user.dto';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private readonly apiService: ApiService,
        private http: HttpClient) { }

    getAll(): Promise<IResponse<UserDto[]>> {
        return this.apiService.get<IResponse<UserDto[]>>("/users");
    }

    getById(id: number) {
        return this.http.get<UserDto>(`${environment.apiUrl}/users/${id}`);
    }
}