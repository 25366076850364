import { SchemeDateSettingDto } from "@app/_services/dto/scheme-date-setting.dto";
import { SchemeInvestorDataOnDateDto } from "@app/_services/dto/scheme-investor-data-on-date.dto";
import { SchemeInvestorDataTableDefinition } from "@app/feature_components/scheme-investor-data-table/scheme-investor-data-table-definitiontype";
import { TableDefinitionUtility } from "../table-definition-helper";

export const getIncomeExpenseSummaryTableDefinition = (
    schemeInvestorDataDate: SchemeInvestorDataOnDateDto,
    schemeDateSetting: SchemeDateSettingDto,
    utility: TableDefinitionUtility
): SchemeInvestorDataTableDefinition => {

    let quarterGrossIncome = schemeInvestorDataDate.siIncomeSummaries?.map(item => item?.quarterIncome).reduce((prev, next) => prev + next, 0);
    let financialYearGrossIncome = schemeInvestorDataDate.siIncomeSummaries?.map(item => item?.financialYearIncome).reduce((prev, next) => prev + next, 0);
    let sinceInceptionGrossIncome = schemeInvestorDataDate.siIncomeSummaries?.map(item => item?.incomeSinceInception).reduce((prev, next) => prev + next, 0);


    let incomeRows = schemeInvestorDataDate.siIncomeSummaries.map(x => {
        return {
            data:
                [
                    `<i>`.concat('&nbsp;'.repeat(5).concat(x.incomeTitle?.concat(x.note ? '&nbsp;<sup>'.concat(x.noteIndex?.toString()).concat('</sup>') : ''),)).concat('</i>'),
                    utility.currency(x?.quarterIncome),
                    utility.currency(x?.financialYearIncome),
                    utility.currency(x?.incomeSinceInception)
                ]
        }
    });

    let quarterGrossExpenseAllocation = schemeInvestorDataDate.siExpenseAllocationSummaries?.map(item => item?.quarterExpense).reduce((prev, next) => prev + next, 0);
    let financialYearGrossExpenseAllocation = schemeInvestorDataDate.siExpenseAllocationSummaries?.map(item => item?.financialYearExpense).reduce((prev, next) => prev + next, 0);
    let sinceInceptionGrossExpenseAllocation = schemeInvestorDataDate.siExpenseAllocationSummaries?.map(item => item?.expenseSinceInception).reduce((prev, next) => prev + next, 0);


    let expenseAllocationRows = schemeInvestorDataDate.siExpenseAllocationSummaries?.map(x => {
        return {
            data:
                [
                    `<i>`.concat('&nbsp;'.repeat(5).concat(x?.expenseTitle.concat(x.note ? '&nbsp;<sup>'.concat(x.noteIndex?.toString()).concat('</sup>') : ''),)).concat('</i>'),
                    utility.currency(x?.quarterExpense),
                    utility.currency(x?.financialYearExpense),
                    utility.currency(x?.expenseSinceInception)
                ]
        }
    });

    let quarterGrossSetupAllocation = schemeInvestorDataDate.siSetupAllocationSummaries?.map(item => item?.quarterExpense).reduce((prev, next) => prev + next, 0);
    let financialYearGrossSetupAllocation = schemeInvestorDataDate.siSetupAllocationSummaries?.map(item => item?.financialYearExpense).reduce((prev, next) => prev + next, 0);
    let sinceInceptionGrossSetupAllocation = schemeInvestorDataDate.siSetupAllocationSummaries?.map(item => item?.expenseSinceInception).reduce((prev, next) => prev + next, 0);


    let setupAllocationRows = schemeInvestorDataDate.siSetupAllocationSummaries?.map(x => {
        return {
            data:
                [
                    `<i>`.concat('&nbsp;'.repeat(5).concat(x?.expenseTitle.concat(x.note ? '&nbsp;<sup>'.concat(x.noteIndex?.toString()).concat('</sup>') : ''),)).concat('</i>'),
                    utility.currency(x?.quarterExpense),
                    utility.currency(x?.financialYearExpense),
                    utility.currency(x?.expenseSinceInception)
                ]
        }
    });


    let quarterGrossDistribution = schemeInvestorDataDate.siDistributionSummaries?.map(item => item?.quarterExpense).reduce((prev, next) => prev + next, 0);
    let financialYearGrossDistribution = schemeInvestorDataDate.siDistributionSummaries?.map(item => item?.financialYearExpense).reduce((prev, next) => prev + next, 0);
    let sinceInceptionGrossDistribution = schemeInvestorDataDate.siDistributionSummaries?.map(item => item?.expenseSinceInception).reduce((prev, next) => prev + next, 0);


    let distributionRows = schemeInvestorDataDate.siDistributionSummaries?.map(x => {
        return {
            data:
                [
                    `<i>`.concat('&nbsp;'.repeat(5).concat(x?.expenseTitle.concat(x.note ? '&nbsp;<sup>'.concat(x.noteIndex?.toString()).concat('</sup>') : ''),)).concat('</i>'),
                    utility.currency(x?.quarterExpense),
                    utility.currency(x?.financialYearExpense),
                    utility.currency(x?.expenseSinceInception)
                ]
        }
    });

    return {
        topNotes: ['All figures in INR'],
        bottomNotes: ['# Balance is unrealised Income (included in NAV) and expected to be distributed upon realisation.'],
        headerRowDefinitions:
            [
                [
                    {
                        text: 'INCOME AND EXPENSE SUMMARY' +
                            (schemeDateSetting?.incomeAndExpenseSummaryNote ?
                                '&nbsp;<sup>'.concat(schemeDateSetting?.incomeAndExpenseSummaryNoteIndex?.toString()).concat('</sup>') :
                                ''),
                        colSpan: 4,
                        rowSpan: 1
                    }
                ],
                [
                    {
                        text: 'PARTICULARS',
                        colSpan: 1,
                        rowSpan: 1
                    },
                    {
                        text: schemeInvestorDataDate.schemeDateSetting?.incomeExpenseQuarterColumnText,
                        colSpan: 1,
                        rowSpan: 1,
                        css: 'text-align:right'
                    },
                    {
                        text: schemeInvestorDataDate.schemeDateSetting?.incomeExpenseFinancialYearColumnText,
                        colSpan: 1,
                        rowSpan: 1,
                        css: 'text-align:right'
                    },
                    {
                        text: schemeInvestorDataDate.schemeDateSetting?.incomeExpenseSinceInceptionColumnText,
                        colSpan: 1,
                        rowSpan: 1,
                        css: 'text-align:right'
                    }
                ]
            ],
        dataRows: [
            {
                css: 'font-weight:bold',
                data:
                    [
                        'Gross Income (K)',
                        utility.rightAligned(utility.currency(quarterGrossIncome)),
                        utility.rightAligned(utility.currency(financialYearGrossIncome)),
                        utility.rightAligned(utility.currency(sinceInceptionGrossIncome))
                    ]
            },
            ...incomeRows,
            {
                css: 'font-weight:bold',
                data:
                    [
                        'Less: Expense Allocation (L)',
                        utility.rightAligned(utility.currency(quarterGrossExpenseAllocation)),
                        utility.rightAligned(utility.currency(financialYearGrossExpenseAllocation)),
                        utility.rightAligned(utility.currency(sinceInceptionGrossExpenseAllocation))
                    ]
            },
            ...expenseAllocationRows,
            {
                css: 'font-weight:bold',
                data:
                    [
                        'Less: Set-up Allocation (M)',
                        utility.rightAligned(utility.currency(quarterGrossSetupAllocation)),
                        utility.rightAligned(utility.currency(financialYearGrossSetupAllocation)),
                        utility.rightAligned(utility.currency(sinceInceptionGrossSetupAllocation))
                    ]
            },
            ...setupAllocationRows,
            {
                css: 'font-weight:bold',
                data: [
                    'Less: Distribution (N)',
                    utility.rightAligned(utility.currency(quarterGrossDistribution)),
                    utility.rightAligned(utility.currency(financialYearGrossDistribution)),
                    utility.rightAligned(utility.currency(sinceInceptionGrossDistribution))
                ]
            },
            ...distributionRows,
            {
                css: 'font-weight:bold',
                data:
                    [
                        'Balance (O = K - L - M - N) #',
                        '',
                        '',
                        utility.rightAligned(utility.currency((sinceInceptionGrossIncome - sinceInceptionGrossExpenseAllocation - sinceInceptionGrossSetupAllocation - sinceInceptionGrossDistribution)))
                    ]
            },
        ],
        dataColumnDefinitions: [
            { widthPercent: 40 },
            { widthPercent: 20, css: 'text-align:right' },
            { widthPercent: 20, css: 'text-align:right' },
            { widthPercent: 20, css: 'text-align:right' }
        ]

    }
}