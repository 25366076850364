import { DtoBase } from "./base.dto";

export class RegisterUserDto extends DtoBase<number> {
  public fullName: string = '';
  public email: string = '';
}

export class AddFundHouseAdminDto extends RegisterUserDto {
  public fundHouseId: number;
}

export class AddFundAdminDto extends RegisterUserDto {
  public fundId: number;
}

export class UpdateFundHouseAdminDto {
  public fullName: string = '';
  public email: string = '';
  public isActive: boolean = false;
}

export class UpdateFundAdminDto {
  public fullName: string = '';
  public email: string = '';
  public isActive: boolean = false;
}