import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatSnackBar, MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { AlertComponent } from '@app/alert/alert.component';
@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  private readonly ERROR_ERROR_GENERIC_MESSAGE = `Oops something went wrong!`;
  dialogTitle: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  public constructor(private readonly dialog: MatDialog, private snackBar: MatSnackBar) {

  }

  public showError(message: string = null) {
    this.dialog.open(AlertComponent, {
      panelClass: 'error-dialog',
      data: { message: message || this.ERROR_ERROR_GENERIC_MESSAGE, isError: true }
    });
  }

  public showInfo(message: string, title: string = "Message", status: string = "") {

    this.snackBar.open(message, '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 3000,
      panelClass: 'snack-bar-info-panel',
    });

    // let alterDialog = new AlertDialog();
    // alterDialog.message = message;
    // alterDialog.title = title;
    // alterDialog.status = status;

    // return new Promise((resolve) => {
    //   let dialogRef = this.dialog.open(AlertComponent, {
    //     data: alterDialog,
    //     panelClass: 'dialog-custom',
    //     autoFocus: false,
    //   });

    //   dialogRef.afterClosed().subscribe(() => {
    //     resolve();
    //   });
    // });
  }

  public showOnce(message: string): Promise<void> {
    if (!message || localStorage.getItem(btoa(message))) {
      return new Promise((resolve, _) => { resolve(); });
    }

    localStorage.setItem(btoa(message), "1");

    return new Promise((resolve) => {
      let dialogRef = this.dialog.open(AlertComponent, { data: message });
      dialogRef.afterClosed().subscribe(() => {
        resolve();
      });
    });
  }

  public showSuccessNotification(message: string) {
    // this.snackBar.open(message, null, {
    //   duration: 3000,
    //   verticalPosition: 'bottom',
    //   panelClass: 'success-notification-panel',
    // })
  }
}
function inject(MatSnackBar: any) {
  throw new Error('Function not implemented.');
}

