import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@environments/environment';
import moment from "moment";

@Pipe({
    name: 'kpfsDate'
})


export class KpfsDatePipe implements PipeTransform {
    transform(date?: Date): string {
        if (date) {
            return moment.utc(date).local().format(environment.dateFormat);
        }
        return '';
    }
}


@Pipe({
    name: 'kpfsDateTime'
})


export class KpfsDateTimePipe implements PipeTransform {
    transform(date?: Date): string {
        if (date) {
            return moment.utc(date).local().format(environment.dateTimeFormat);
        }
        return '';
    }
}