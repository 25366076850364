<div class="scheme-investor-data-table">
    <div *ngIf="this.tableDefinition.topNotes?.length>0" class="top-notes">
        <div *ngFor="let note of this.tableDefinition.topNotes">
            {{note}}
        </div>
    </div>
    <table *ngIf="tableDefinition">
        <tr *ngFor="let headerRowArray of this.tableDefinition?.headerRowDefinitions;let i=index"
            class="{{'header-row-'+i}}">
            <th *ngFor="let headerRow of headerRowArray" [attr.rowspan]="headerRow?.rowSpan"
                [attr.colspan]="headerRow?.colSpan" [style]="headerRow.css" [innerHTML]="headerRow.text  | safeHtml">
            </th>
        </tr>
        <tr class="data-row" *ngFor="let row of tableDefinition?.dataRows">
            <td class="{{'column-'+i}}" *ngFor="let data of row?.data;let i=index" [innerHTML]="data | safeHtml"
                [style]="row?.css ?? tableDefinition?.dataColumnDefinitions[i]?.css"
                [width]="tableDefinition?.dataColumnDefinitions[i]?.widthPercent+'%'">
            </td>
        </tr>
        <tr *ngFor="let footerRowArray of this.tableDefinition?.footerRowDefinitions;let i=index"
            class="{{'footer-row-'+i}}">
            <td *ngFor="let footerRow of footerRowArray" [attr.rowspan]="footerRow?.rowSpan"
                [attr.colspan]="footerRow?.colSpan" [style]="footerRow.css" [innerHTML]="footerRow?.text  | safeHtml">
            </td>
        </tr>
    </table>
    <div *ngIf="this.tableDefinition.bottomNotes?.length>0" class="bottom-notes">
        <div *ngFor="let note of this.tableDefinition.bottomNotes">
            {{note}}
        </div>
    </div>
</div>